import {
  brandingDataFetched,
  brandingDataUpdated,
  setColors,
  uploadClientLogo,
} from "../slice/branding";
import { call, put, takeEvery } from "redux-saga/effects";

import { BrandingActions } from "../types";
import { sendApiRequest } from "../../lib/axios";

function* createClientBranding(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "branding/create/",
    "post",
  );
  yield put(brandingDataFetched(result));
}

function* fetchClientBranding(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "branding/fetch/",
    "get",
  );
  yield put(brandingDataFetched(result));
}

function* updateClientBranding(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "branding/update/",
    "post",
  );
  yield put(brandingDataUpdated(result));
}

function* updateAppColors(action) {
  try {
    yield put({ type: setColors, payload: action.payload });
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "updateAppColors", message: e.message });
  }
}

function* uploadClientBrandingLogo(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "branding/logo/upload/",
    "post",
    "multipart",
  );
  yield put(uploadClientLogo(result));
}

export function* watchBrandingAsync() {
  yield takeEvery(BrandingActions.SET_COLORS_ACTION, updateAppColors);
  yield takeEvery(
    BrandingActions.UPDATE_CLIENT_BRANDING_ACTION,
    updateClientBranding,
  );
  yield takeEvery(
    BrandingActions.FETCH_CLIENT_BRANDING_ACTION,
    fetchClientBranding,
  );
  yield takeEvery(
    BrandingActions.CREATE_CLIENT_BRANDING_ACTION,
    createClientBranding,
  );
  yield takeEvery(
    BrandingActions.UPLOAD_CLIENT_LOGO_ACTION,
    uploadClientBrandingLogo,
  );
}
