import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppBar from "components/AppBar";
import CErrorModal from "components/custom/CErrorModal";
import CssBaseline from "@mui/material/CssBaseline";
import CustomLoader from "components/custom/CustomLoader";
import { ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import routes from "./routes";
import theme, { GlobalStyled, themeConfig } from "./theme";
import { useRoutes } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";

const App = () => {
  const content = useRoutes(routes);

  const { isLoading, apiErrorMessage } = useSelector(
    (state) => state.commonReducer,
  );

  const [, setShowErrorMessageModal] = useState(false);
  const [, setErrorMessage] = useState("");

  useEffect(() => {
    if (apiErrorMessage !== "") {
      setShowErrorMessageModal(true);
      setErrorMessage(apiErrorMessage);
    }
  }, [apiErrorMessage]);

  // const handleClose = (event, reason) => {
  //   if (reason !== "backdropClick") {
  //     setShowErrorMessageModal(false);
  //   }
  // };
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    silentMultipleInit: true,
  });
  // datadogLogs.setUser({
  //   customerId: customerStatus && customerStatus.account.customer_id,
  //   email: customerStatus && customerStatus.account.email,
  // });
  datadogLogs.setUserProperty("type", "partner");

  return (
    <>
      <CssBaseline />
      <GlobalStyled config={themeConfig} />
      <ThemeProvider theme={theme}>
        <AppBar widget={content} />
        <Toaster
          gutter={8}
          toastOptions={{
            error: {
              style: {
                background: "#FFCCCC",
              },
            },
          }}
        />
        {isLoading ? <CustomLoader /> : null}

        <CErrorModal />
      </ThemeProvider>
    </>
  );
};
export default App;
