import { put, takeEvery } from "redux-saga/effects";

import { ReportsActions } from "../types";
import { fetch } from "../slice/reports";

function* getReports(action) {
  try {
    yield put(fetch(action));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_REPORTS_ACTION_FAILED", message: e.message });
  }
}

export function* watchReportsAsync() {
  yield takeEvery(ReportsActions.GET_REPORTS_ACTION, getReports);
}
