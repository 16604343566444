class AppMode {
  isInSandboxMode() {
    let appMode;

    if (window.localStorage.getItem("appMode")) {
      appMode = localStorage.getItem("appMode");
    }
    return appMode === "sandbox";
  }

  setModeAsSandbox() {
    window.localStorage.setItem("appMode", "sandbox");
  }

  setModeAsProduction() {
    window.localStorage.setItem("appMode", "production");
  }
}

const AppModeObj = new AppMode();

export default AppModeObj;
