export const PaymentMessages = {
  SUCCESS_CUSTOMER_MESSAGE: "Account has been registered successfully.",
};

export const AccountCreateMessages = {
  SUCCESS_CREATE_CUSTOMER_MESSAGE: "Account has been created successfully.",
  ERROR_CREATE_CUSTOMER_MESSAGE: "Some error occurred, please try again",
  ERROR_LOGIN_MESSAGE: "The user credentials were incorrect.",
  ERROR_REGISTER_CUSTOMER_MESSAGE:
    "Some error occurred while registering your account, please try again",
  ERROR_PLAID_TOKEN: "Due to some error, plaid token is not retrieved",
  ERROR_PLAID_ADD_BANK:
    "Some error ocurred while registering bank, please try again",
};

export const AccountLoginMessages = {
  SUCCESS_LOGIN_MESSAGE: "You are logged in.",
  ERROR_LOGIN_MESSAGE: "Unable to login, please try again",
};

export const KeyMessages = {
  ERROR_FETCH_KEY_MESSAGE: "Some error occurred, please try again",
  ERROR_GENERATE_KEY_MESSAGE:
    "Some error occurred while generating keys, please try again",
  ERROR_DELETE_KEY_MESSAGE:
    "Some error occurred while deleting keys, please try again",
};

export const ApiLogs = {
  ERROR_FETCH_LOG_MESSAGE:
    "Some error occurred while fetching logs, please try again",
  ERROR_EXPORT_LOG_MESSAGE:
    "Some error occurred while exporting logs, please try again",
  ERROR_FETCH_API_DOCS_MESSAGE:
    "Some error occurred while retrieving api documents, please try again",
  ERROR_FETCH_ARCHIVED_LOG_MESSAGE:
    "Some error occurred while fetching archived logs, please try again",
};

export const Users = {
  ERROR_FETCH_USERS_MESSAGE:
    "Some error occurred while fetching Users, please try again",
  ERROR_CREATE_USERS_MESSAGE:
    "Some error occurred while creating user, please try again",
  SUCCESS_CREATE_USERS_MESSAGE: "User has been added successfully",
  ERROR_UPDATE_USERS_MESSAGE:
    "Some error occurred while updating user, please try again",
};
export const ContactUS = {
  ERROR_SUBMIT_TICkET_MESSAGE:
    "Some error occurred while submitting your feedback, please try again",
};
