import { Grid, List, ListItemButton, ListItemText, Badge } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import { setIsInSandboxMode, setLoading } from "redux/slice/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import { CMenuItem } from "./AppBarStyles.js";
import { AccountMenuItems } from "../constants/Common";
import MoreIcon from "@mui/icons-material/MoreVert";
import AppMode from "../components/app_mode";
import { AuthActions } from "redux/types";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItems from "./MenuItems";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { resetAuth } from "redux/slice/authentication";
import theme from "../theme";
import { useLocation, useNavigate } from "react-router";
import CompModal from "./custom/CompModal.jsx";
import ChangePassword from "pages/ChangePassword/index.jsx";
import ContactUs from "pages/ContactUs/index.jsx";
import MenuItem from "@mui/material/MenuItem";
import { sendDDLog } from "lib/datadogService";

const UserToken = window.localStorage.getItem("user_token");
const drawerWidth = 260;

const MyAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function AppBar({ widget }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isInSandboxModeActive, setIsInSandboxModeActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(true);
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  const [contactModal, setContactModal] = React.useState(false);
  const [releaseView, setReleaseView] = React.useState(false);
  const [anchorElEnv, setAnchorElEnv] = React.useState(null);
  const openEnvMenu = Boolean(anchorElEnv);

  const handleClose = () => {
    setAnchorElEnv(null);
  };
  const handleClickListItem = (event) => {
    setAnchorElEnv(event.currentTarget);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const { logoutSuccess } = useSelector((state) => state.authentication);

  const { isInSandboxMode } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (logoutSuccess) {
      dispatch(resetAuth());
      dispatch(setLoading(false));
      sendDDLog(
        "Successfully Logout",
        {
          ScreenName: "/logout",
        },
        "info",
      );
      navigate("/logout");
    }
  }, [logoutSuccess, dispatch, navigate]);

  useEffect(() => {
    setIsInSandboxModeActive(AppMode.isInSandboxMode());
  }, []);

  function shouldShowAppBar() {
    return location.pathname !== "/signin" && location.pathname !== "/";
  }

  const logout = () => {
    dispatch(setLoading(true));
    dispatch({
      type: AuthActions.LOGOUT_ACTION,
      payload: {
        refresh: window.localStorage.getItem("refresh_token"),
      },
    });
  };

  const changeAppMode = () => {
    var mode = !isInSandboxModeActive;
    if (mode) {
      AppMode.setModeAsSandbox();
    } else {
      AppMode.setModeAsProduction();
    }
    dispatch(setIsInSandboxMode(mode));
    window.location.reload();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);
  const menuId = "primary-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <CMenuItem
        onClick={() => {
          setChangePasswordModal(true);
          handleMenuClose();
        }}
      >
        {AccountMenuItems.MENU_ITEM_1}
      </CMenuItem>
      <CMenuItem
        onClick={() => {
          sendDDLog(
            "Contact Us Clicked",
            {
              ScreenName: "ContactUs",
            },
            "info",
          );
          setContactModal(true);
          handleMenuClose();
        }}
      >
        {AccountMenuItems.MENU_ITEM_2}
      </CMenuItem>
      <CMenuItem
        onClick={() => {
          setReleaseView(true);
          handleMenuClose();
          sendDDLog(
            "Releases Clicked",
            {
              ScreenName: "/releases",
            },
            "info",
          );
          navigate("/releases");
        }}
      >
        {AccountMenuItems.MENU_ITEM_3}
      </CMenuItem>
      <CMenuItem
        onClick={() => {
          handleMenuClose();
          logout();
        }}
      >
        {AccountMenuItems.MENU_ITEM_4}
      </CMenuItem>
    </Menu>
  );
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeInfo = () => {
    setChangePasswordModal(false);
    setContactModal(false);
  };
  {
    const appModeText = isInSandboxMode === true ? "Sandbox" : "Production";
    const logoMarginStart = open === true ? "270px" : "40px";
    const options = ["Sandbox", "Production"];
    return (
      <ThemeProvider theme={theme}>
        <Box display="flex">
          <CompModal
            open={changePasswordModal}
            dialogTitle={`Change Password`}
            Component={<ChangePassword handleCloseInfo={closeInfo} />}
            handleCancel={() => setChangePasswordModal(false)}
          />
          <CompModal
            open={contactModal}
            dialogTitle={`Contact Us`}
            Component={<ContactUs handleCloseInfo={closeInfo} />}
            handleCancel={() => setContactModal(false)}
          />
          <CssBaseline />

          {shouldShowAppBar() &&
            !releaseView &&
            location.pathname !== "/releases" && (
              <AppDrawer open={open} variant="permanent">
                <Toolbar
                  sx={{
                    bgcolor: "#28335B",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    px: [1],
                  }}
                >
                  <IconButton
                    edge="start"
                    color="disabled"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{ mr: 1.5 }}
                  >
                    {open && <ChevronLeftIcon />}
                    {!open && <MenuIcon />}
                  </IconButton>
                </Toolbar>
                <Divider />
                <MenuItems />
              </AppDrawer>
            )}
          {shouldShowAppBar() && (
            <MyAppBar
              open={open}
              sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
              style={{ background: "#28335B", height: "64px", width: "100%" }}
            >
              {
                <Toolbar
                  sx={{
                    pr: "24px", // keep right padding when drawer closed
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                      marginRight: "36px",
                      ...(open && { display: "none" }),
                    }}
                  ></IconButton>
                  {shouldShowAppBar() && (
                    <a href="/">
                      <img
                        alt="logo"
                        src="https://growcredit-assets.s3.us-west-2.amazonaws.com/logo_full_light.png"
                        width={120}
                        style={{
                          marginBottom: "16px",
                          marginTop: "24px",
                          marginLeft:
                            location.pathname === "/releases"
                              ? "3px"
                              : logoMarginStart,
                        }}
                      />
                    </a>
                  )}
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ flexGrow: 1 }} />
                  <Box sx={{ flexGrow: 1 }} />
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List
                      component="nav"
                      sx={{
                        mr: 4,
                      }}
                    >
                      <ListItemButton
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        aria-expanded={openEnvMenu ? "true" : undefined}
                        onClick={handleClickListItem}
                      >
                        <ListItemText
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          {appModeText}{" "}
                          {process.env.REACT_APP_ENV !== "production"
                            ? `(${process.env.REACT_APP_ENV})`
                            : ""}
                        </ListItemText>
                      </ListItemButton>
                    </List>
                    <Menu
                      id="lock-menu"
                      anchorEl={anchorElEnv}
                      open={openEnvMenu}
                      onClose={handleClose}
                      PaperProps={{ sx: { width: "250px" } }}
                    >
                      {options.map((option) => (
                        <Box
                          key={option}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "80%",
                          }}
                        >
                          <MenuItem
                            key={option}
                            onClick={() => changeAppMode()}
                            sx={{
                              width: "50%",
                              fontWeight:
                                option === appModeText ? "bold" : "normal",
                              "&:hover": {
                                backgroundColor: "#FFF",
                              },
                            }}
                          >
                            {option}
                          </MenuItem>
                          {option === appModeText && (
                            <Box
                              sx={{
                                bgcolor: "Highlight",
                                width: "110px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Badge badgeContent={"Active"} color="success" />
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Menu>
                  </Grid>
                  {UserToken && (
                    <Box sx={{ display: { xs: "flex" } }}>
                      <IconButton
                        size="large"
                        aria-label="show more"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleMenuOpen}
                        onClose={handleMenuClose}
                        color="inherit"
                      >
                        <MoreIcon color="inherit" />
                      </IconButton>
                    </Box>
                  )}
                </Toolbar>
              }
              {renderMenu}
            </MyAppBar>
          )}

          <Box
            sx={{
              backgroundColor: "transparent",
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container
              maxWidth={location.pathname === "/releases" ? "xl" : "lg"}
              sx={{ mt: 12, mb: 1 }}
            >
              {widget}
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}
