import AppMode from "../../components/app_mode";
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "commonReducer",
  initialState: {
    isLoading: false,
    apiErrorMessage: "",
    showSignInScreen: false,
    isInSandboxMode: AppMode.isInSandboxMode(),
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
      //   return state;
    },
    setAPIErrorMessage: (state, action) => {
      state.apiErrorMessage = action.payload;
      //   return state;
    },
    navigateToSignInScreen: (state, action) => {
      state.showSignInScreen = action.payload;
      //   return state;
    },
    showErrormessage: (state, action) => {
      state.showErrorModal = true;
      state.toastErrorMessage = action.payload;
    },
    removeErrorMessage: (state) => {
      state.showErrorModal = false;
      state.toastErrorMessage = "";
    },
    setIsInSandboxMode: (state, action) => {
      state.isInSandboxMode = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.showSignInScreen = false;
      state.isInSandboxMode = false;
    },
  },
});
export const {
  setLoading,
  setAPIErrorMessage,
  navigateToSignInScreen,
  showErrormessage,
  removeErrorMessage,
  setIsInSandboxMode,
  reset,
} = slice.actions;
export default slice.reducer;
