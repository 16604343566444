import * as Yup from "yup";
import { AuthActions } from "redux/types";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { setLoading, showErrormessage } from "redux/slice/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import CButton from "components/custom/CButton";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import {
  GridContainer,
  CenterGridItem,
  Clear,
  Done,
  ValidationText,
  PasswordGridItem,
} from "./styles.js";
import { ChangePasswordScreen } from "constants/Contents";
import toast from "react-hot-toast";
import { resetChangePassword } from "redux/slice/authentication";
import { sendDDLog } from "lib/datadogService";

const ChangePassword = (props) => {
  const { handleCloseInfo } = props;
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const formInitialValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [validationMessage, setValidationMessage] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const {
    changePassValidationError,
    changePasswordSuccess,
    changePasswordSuccessMsg,
    changePasswordError,
    changePasswordErrorMsg,
  } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (changePasswordError) {
      dispatch(setLoading(false));
      dispatch(showErrormessage(changePasswordErrorMsg));
      dispatch(resetChangePassword());
    }
  }, [changePasswordError, dispatch, changePasswordErrorMsg]);
  useEffect(() => {
    if (changePasswordSuccess) {
      handleCloseInfo();
      sendDDLog(
        "change Password Success",
        {
          ScreenName: "changePassword",
        },
        "info",
      );
      dispatch(setLoading(false));
      toast.success(changePasswordSuccessMsg, {
        id: "success1",
      });
      dispatch(resetChangePassword());
    }
  }, [
    changePasswordSuccess,
    changePasswordSuccessMsg,
    dispatch,
    handleCloseInfo,
  ]);

  useEffect(() => {
    if (Object.keys(changePassValidationError).length !== 0) {
      dispatch(setLoading(false));
      setValidationMessage(changePassValidationError);
      dispatch(resetChangePassword());
    }
  }, [changePassValidationError, dispatch]);

  const validationSchemaModel = Yup.object().shape({
    oldPassword: Yup.string().required(
      ChangePasswordScreen.REQUIRED_OLD_PASSWORD_ERROR,
    ),
    newPassword: Yup.string()
      .notOneOf(
        [Yup.ref("oldPassword"), null],
        ChangePasswordScreen.NEW_PASSWORD_NOT_SAME_ERROR,
      )
      .required(ChangePasswordScreen.REQUIRED_PASSWORD_ERROR)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        ChangePasswordScreen.VALID_PASSWORD_ERROR,
      ),
    confirmPassword: Yup.string()
      .required(ChangePasswordScreen.REQUIRED_CONFIEM_PASSWORD_ERROR)
      .oneOf(
        [Yup.ref("newPassword"), null],
        ChangePasswordScreen.MATCH_PASSWORD,
      ),
  });

  const handleClickShowPassword = (type) => {
    switch (type) {
      case "old":
        return setShowOldPassword(!showOldPassword);
      case "new":
        return setShowNewPassword(!showNewPassword);
      case "confirm":
        return setShowConfirmPassword(!showConfirmPassword);

      default:
        return null;
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    dispatch({
      type: AuthActions.CHANGE_PASSWORD,
      payload: {
        old_password: values.oldPassword,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword,
      },
    });
  };

  return (
    <GridContainer container columnGap={0} rowGap={0}>
      <CenterGridItem item xs={12}>
        <Formik
          initialValues={formInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ touched, errors, getFieldProps }) => (
            <Form id="changePassword">
              <Grid container rowGap={3}>
                <Grid item xs={12}>
                  <TextField
                    size="medium"
                    fullWidth
                    label={ChangePasswordScreen.OLD_PASSWORD_LABEL}
                    name="oldPassword"
                    type={showOldPassword ? "text" : "password"}
                    onChange={(e) => {
                      formikRef.current.setFieldValue(
                        "oldPassword",
                        e.target.value,
                      );
                      setValidationMessage({
                        ...validationMessage,
                        old_password: " ",
                      });
                    }}
                    required
                    InputProps={{
                      sx: {
                        bgcolor: "text.light",
                        "& fieldset": { border: "none" },
                        borderRadius: "6px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword("old")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      Boolean(touched.oldPassword && errors.oldPassword) ||
                      validationMessage.old_password
                    }
                    helperText={
                      (touched.oldPassword && errors.oldPassword) ||
                      validationMessage.old_password
                    }
                    {...getFieldProps("oldPassword")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="medium"
                    fullWidth
                    label={ChangePasswordScreen.PASSWORD_LABEL}
                    name="newPassword"
                    type={showNewPassword ? "text" : "password"}
                    required
                    InputProps={{
                      sx: {
                        bgcolor: "text.light",
                        "& fieldset": { border: "none" },
                        borderRadius: "6px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword("new")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      Boolean(touched.newPassword && errors.newPassword) ||
                      validationMessage.new_password
                    }
                    helperText={
                      (touched.newPassword && errors.newPassword) ||
                      validationMessage.new_password
                    }
                    {...getFieldProps("newPassword")}
                  />
                </Grid>
                <PasswordGridItem item xs={6}>
                  {!/^(?=.{8,})/.test(
                    formikRef.current &&
                      formikRef.current.values.newPassword !== "" &&
                      formikRef.current.values.newPassword,
                  ) ? (
                    <Clear />
                  ) : (
                    <Done />
                  )}
                  <ValidationText>8 Characters</ValidationText>
                </PasswordGridItem>
                <PasswordGridItem item xs={6}>
                  {!/^(?=.*[a-z])/.test(
                    formikRef.current && formikRef.current.values.newPassword,
                  ) ? (
                    <Clear />
                  ) : formikRef.current &&
                    formikRef.current.values.newPassword ? (
                    <Done />
                  ) : (
                    <Clear />
                  )}
                  <ValidationText>1 lowercase letter</ValidationText>
                </PasswordGridItem>
                <PasswordGridItem item xs={6}>
                  {!/^(?=.*[A-Z])/.test(
                    formikRef.current &&
                      formikRef.current.values.newPassword !== "" &&
                      formikRef.current.values.newPassword,
                  ) ? (
                    <Clear />
                  ) : (
                    <Done />
                  )}
                  <ValidationText>1 uppercase letter</ValidationText>
                </PasswordGridItem>
                <PasswordGridItem item xs={6}>
                  {!/^(?=.*[0-9])/.test(
                    formikRef.current &&
                      formikRef.current.values.newPassword !== "" &&
                      formikRef.current.values.newPassword,
                  ) ? (
                    <Clear />
                  ) : (
                    <Done />
                  )}
                  <ValidationText>1 number</ValidationText>
                </PasswordGridItem>
                <Grid item xs={12}>
                  <TextField
                    size="medium"
                    fullWidth
                    label={ChangePasswordScreen.CONFIRM_PASSWORD_LABEL}
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    InputProps={{
                      sx: {
                        bgcolor: "text.light",
                        "& fieldset": { border: "none" },
                        borderRadius: "6px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword("confirm")}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      Boolean(
                        touched.confirmPassword && errors.confirmPassword,
                      ) || validationMessage.confirm_password
                    }
                    helperText={
                      (touched.confirmPassword && errors.confirmPassword) ||
                      validationMessage.confirm_password
                    }
                    {...getFieldProps("confirmPassword")}
                  />
                </Grid>
                <CenterGridItem item xs={12}>
                  <CButton
                    size="large"
                    variant="contained"
                    type="submit"
                    sx={{
                      whiteSpace: "nowrap",
                      // width: "50%",
                      mt: 2,
                    }}
                  >
                    {ChangePasswordScreen.BUTTON}
                  </CButton>
                </CenterGridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      </CenterGridItem>
    </GridContainer>
  );
};

export default ChangePassword;
