import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";

export const GridContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

export const GridItem = styled(Grid)(() => ({}));
