import { PERMISSIONS } from "../constants/Common/roles";
// import {useSelector} from "react-redux"; // Temp comment

export const usePermissions = () => {
  // const {userRole} = useSelector((state) => state.authentication); // Temp comment
  const localStorageRole = window.localStorage.getItem("role");
  const userRole =
    localStorageRole !== null ? parseInt(localStorageRole) : null;

  const hasPermission = (permissionName) => {
    return PERMISSIONS[permissionName].includes(parseInt(userRole));
  };

  return { hasPermission };
};
