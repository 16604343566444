import InputField from "./InputField";
import PropTypes from "prop-types";
import React from "react";
import SelectField from "./SelectField";
import Textarea from "./Textarea";
import InputFieldWithInputProps from "./InputFieldWithInputProps";

const FormikControl = (props) => {
  const {
    control,
    label,
    name,
    error,
    helperText,
    readOnly = false,
    required,
    options,
    disabled,
    // fullWidth = true,
    handleChange,
    placeholder = "",
    inputProps,
    InputProps,
    customOnBlur,
    // dateOrTimeOnly,
    // views,
    // minDateTime,
    // maxDateTime,
    fieldInputProps,
    multiline = false,
    rows,
    maxLength,
    ...rest
  } = props;
  switch (control) {
    case "InputField":
      return (
        <InputField
          disabled={disabled}
          label={label}
          fieldInputProps={fieldInputProps}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          readOnly={readOnly}
          placeholder={placeholder}
          customOnBlur={customOnBlur}
          handleChange={handleChange}
          {...rest}
        />
      );

    case "InputFieldWithInputProps":
      return (
        <InputFieldWithInputProps
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          placeholder={placeholder}
          customOnBlur={customOnBlur}
          handleChange={handleChange}
          inputProps={inputProps}
          InputProps={InputProps}
          {...rest}
        />
      );

    case "SelectField":
      return (
        <SelectField
          readOnly={readOnly}
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          options={options}
          handleChange={handleChange}
          {...rest}
        />
      );

    case "Textarea":
      return (
        <Textarea
          disabled={disabled}
          label={label}
          error={error}
          name={name}
          helperText={helperText}
          required={required}
          multiline={multiline}
          rows={rows}
          maxLength={maxLength}
          readOnly={readOnly}
          {...rest}
        />
      );

    default:
      return null;
  }
};

FormikControl.propTypes = {
  control: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  rest: PropTypes.any,
  error: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.any,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  selectedvalue: PropTypes.any,
  customOnBlur: PropTypes.func,
  // dateOrTimeOnly: PropTypes.oneOf(["date", "time"]),
  // views: PropTypes.any,
  // minDateTime: PropTypes.any,
  // maxDateTime: PropTypes.any,
};

export default FormikControl;
