import { call, put, takeEvery } from "redux-saga/effects";

import { apiLogsActions, DocumentsAction } from "../types";
import {
  fetch,
  fetchDownloadLogs,
  fetchApiDoc,
  fetchArchivedLogs,
} from "../slice/apilogs";
import { sendApiRequest } from "../../lib/axios";

function* fetchApiLogs(action) {
  try {
    const result = yield call(
      sendApiRequest,
      action.payload.logsEnv,
      `fetch/logs/${action.payload.url}`,
      "post",
    );
    yield put(fetch(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_CHANGE_LOGS_ACTION_FAILED", message: e.message });
  }
}
function* fetchApiDownloadLogs(action) {
  try {
    const result = yield call(
      sendApiRequest,
      null,
      `export/logs/${action.payload.url}`,
      "post",
    );
    yield put(fetchDownloadLogs(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_CHANGE_LOGS_ACTION_FAILED", message: e.message });
  }
}
function* fetchApiArchivedLogs(action) {
  try {
    const result = yield call(
      sendApiRequest,
      null,
      `fetch/exported/logs/${action.payload.url}`,
      "get",
    );
    yield put(fetchArchivedLogs(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_CHANGE_LOGS_ACTION_FAILED", message: e.message });
  }
}
function* fetchApiDocs() {
  try {
    const result = yield call(
      sendApiRequest,
      null,
      `api/collection/?format=openapi`,
      "get",
    );
    yield put(fetchApiDoc(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_CHANGE_LOGS_ACTION_FAILED", message: e.message });
  }
}

export function* watchLogsAsync() {
  yield takeEvery(apiLogsActions.FETCH_API_LOGS_ACTION, fetchApiLogs);
  yield takeEvery(
    apiLogsActions.FETCH_API_DOWNLOAD_LOGS_ACTION,
    fetchApiDownloadLogs,
  );
  yield takeEvery(
    apiLogsActions.FETCH_API_ARCHIVED_LOGS_ACTION,
    fetchApiArchivedLogs,
  );

  yield takeEvery(DocumentsAction.FETCH_API_DOC, fetchApiDocs);
}
