import { call, put, takeEvery } from "redux-saga/effects";
import { sendApiRequest } from "../../lib/axios";
import { ContactUsActions } from "../types";
import { submitTicket } from "../slice/dashboard";

function* sendSupportTicket(action) {
  try {
    const result = yield call(
      sendApiRequest,
      action.payload,
      "contact/support/",
      "post",
    );
    yield put(submitTicket(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
  }
  try {
    yield put(fetch(action));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_DASHBOARD_ACTION_FAILED", message: e.message });
  }
}

export function* watchDashboardAsync() {
  yield takeEvery(ContactUsActions.SUBMIT_TICKET_ACTION, sendSupportTicket);
}
