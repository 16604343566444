export const AppActions = {
  REGISTRATION_CHECK: "REGISTRATION_CHECK",
  LANDING_PAGE_DATA: "LANDING_PAGE_CAPTURE_DATA",
  LANDING_PAGE_EMAIL: "LANDING_PAGE_SAVE_EMAIL",
  CUSTOMER_APPROVAL_STATUS: "CUSTOMER_APPROVAL_STATUS",
  TEXT_APPS_LINK: "TEXT_APPS_LINK",
  SEND_EVENT: "SEND_EVENT",
  LEGAL_AGREEMENT_FEES: "LEGAL_AGREEMENT_FEES",
  LEGAL_AGREEMENT_LOAN: "LEGAL_AGREEMENT_LOAN",
  LEGAL_AGREEMENT_ESIGN: "LEGAL_AGREEMENT_ESIGN",
};

export const AuthActions = {
  LOGIN_ACTION: "LOGIN_ACTION",
  LOGOUT_ACTION: "LOGOUT_ACTION",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  GENERATE_DOCS_ACCESS_TOKEN: "GENERATE_DOCS_ACCESS_TOKEN",
};

export const BrandingActions = {
  SET_COLORS_ACTION: "SET_COLORS_ACTION",
  SET_ICONS_ACTION: "SET_ICONS_ACTION",
  UPDATE_CLIENT_BRANDING_ACTION: "UPDATE_CLIENT_BRANDING_ACTION",
  FETCH_CLIENT_BRANDING_ACTION: "FETCH_CLIENT_BRANDING_ACTION",
  CREATE_CLIENT_BRANDING_ACTION: "CREATE_CLIENT_BRANDING_ACTION",
  UPLOAD_CLIENT_LOGO_ACTION: "UPLOAD_CLIENT_LOGO_ACTION",
  SET_THEME_TYPE: "SET_THEME_TYPE",
};

export const KeysActions = {
  GET_KEYS_ACTION: "GET_KEYS",
  GENERATE_KEYS_ACTION: "GENERATE_KEYS",
  DELETE_KEYS_ACTION: "DELETE_KEYS_ACTION",
};

export const ConfigActions = {
  GET_CLIENT_CONFIG_ACTION: "GET_CLIENT_CONFIG_ACTION",
  UPDATE_CLIENT_CONFIG_ACTION: "UPDATE_CLIENT_CONFIG_ACTION",
  CREATE_CLIENT_CONFIG_ACTION: "CREATE_CLIENT_CONFIG_ACTION",
  SET_CONFIGURATION: "SET_CONFIGURATION",
};

export const WebHookActions = {
  GET_WEBHOOK_ACTION: "GET_WEBHOOK_ACTION",
  ADD_WEBHOOK_ACTION: "ADD_WEBHOOK_ACTION",
  UPDATE_WEBHOOK_ACTION: "UPDATE_WEBHOOK_ACTION",
};

export const WhitelistingActions = {
  GET_WHITELISTINGS_ACTION: "GET_WHITELISTINGS_ACTION",
  ADD_WHITELISTING_DOMAIN_ACTION: "ADD_WHITELISTING_DOMAIN_ACTION",
  UPDATE_WHITELISTING_DOMAIN_ACTION: "UPDATE_WHITELISTING_DOMAIN_ACTION",
};

export const apiLogsActions = {
  FETCH_API_LOGS_ACTION: "FETCH_API_LOGS",
  FETCH_API_DOWNLOAD_LOGS_ACTION: "FETCH_API_DOWNLOAD_LOGS_ACTION",
  FETCH_API_ARCHIVED_LOGS_ACTION: "FETCH_API_ARCHIVED_LOGS_ACTION",
};

export const ReportsActions = {
  GET_REPORTS_ACTION: "GET_REPORTS_ACTION",
};

export const DashboardActions = {
  GET_DASHBOARD_ACTION: "GET_DASHBOARD_ACTION",
};

export const UserManagement = {
  FETCH_USERS_ACTION: "FETCH_USERS_ACTION",
  CREATE_USER_ACTION: "CREATE_USER_ACTION",
  UPDATE_USER_ACTION: "UPDATE_USER_ACTION",
};
export const ContactUsActions = {
  SUBMIT_TICKET_ACTION: "SUBMIT_TICKET_ACTION",
};

export const DocumentsAction = {
  FETCH_API_DOC: "FETCH_API_DOC",
};
