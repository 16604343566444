import { datadogLogs } from "@datadog/browser-logs";

export const sendDDLog = (message, jsonObj, status) => {
  let user_ref = sessionStorage.getItem("user_ref");
  jsonObj["partner_ref"] = user_ref;
  if (status === "info")
    datadogLogs.logger.info(message + " : " + JSON.stringify(jsonObj));
  if (status === "error")
    datadogLogs.logger.error(message + " : " + JSON.stringify(jsonObj));
};
