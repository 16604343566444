import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLoading } from "redux/slice/commonSlice";

const AppContext = createContext();

export const AppProvider = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const [isRegistrationAllowed, setIsRegistrationAllowed] = useState(false);
  const { registrationAllowed } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (registrationAllowed) {
      setIsRegistrationAllowed(registrationAllowed);
      dispatch(setLoading(false));
    }
  }, [registrationAllowed, dispatch]);

  return (
    <AppContext.Provider
      value={{
        isRegistrationAllowed,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
