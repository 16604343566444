import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  primaryColor: "",
};

const reports = createSlice({
  name: "reports",
  initialState,
  reducers: {
    fetch: (state) => {
      state.primaryColor = "";
      return state;
    },
  },
});
export const { fetch } = reports.actions;
export default reports.reducer;
