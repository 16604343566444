import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";

const CompModal = (props) => {
  const {
    open = false,
    maxWidth = "sm",
    dialogTitle,
    Component,
    handleCancel,
    showCloseIcon = true,
  } = props;
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar position="static">
        <DialogTitle id="alert-dialog-title" sx={{ py: 1 }}>
          {dialogTitle}

          {showCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={handleCancel}
              sx={{
                position: "absolute",
                right: { xs: "-4px", md: 8 },
                top: { xs: 4, md: 8 },
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      </AppBar>

      <DialogContent>{Component}</DialogContent>
    </Dialog>
  );
};

export default CompModal;
