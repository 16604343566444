import { call, put, takeEvery } from "redux-saga/effects";

import { KeysActions } from "../types";
import { fetch, generate, deleteIt } from "../slice/keys";
import { sendApiRequest } from "../../lib/axios";

function* getClientKeys() {
  try {
    const result = yield call(sendApiRequest, null, "fetch/keys/", "get");
    yield put(fetch(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_KEYS_ACTION_FAILED", message: e.message });
  }
}
function* generateKeys(action) {
  try {
    const result = yield call(
      sendApiRequest,
      //{ env: "applications" }, // This is a temp addition as API is failing if we remove it
      action.payload,
      "generate/keys/",
      "post",
    );
    yield put(generate(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_KEYS_ACTION_FAILED", message: e.message });
  }
}

function* deleteKeys(action) {
  try {
    const result = yield call(
      sendApiRequest,
      action.payload,
      "delete/application/",
      "post",
    );
    yield put(deleteIt({ result, client_id: action.payload.client_id }));
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "GET_KEYS_ACTION_FAILED", message: e.message });
  }
}

export function* watchKeysAsync() {
  yield takeEvery(KeysActions.GET_KEYS_ACTION, getClientKeys);
  yield takeEvery(KeysActions.GENERATE_KEYS_ACTION, generateKeys);
  yield takeEvery(KeysActions.DELETE_KEYS_ACTION, deleteKeys);
}
