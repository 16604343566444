import { usePermissions } from "../hooks/usePermissions";
import { Navigate } from "react-router";

export const ProtectedRoute = ({ permission, children }) => {
  const { hasPermission } = usePermissions();

  if (!hasPermission(permission)) {
    return <Navigate to={"/"} />;
  }

  return children;
};
