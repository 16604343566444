import AppMode from "../components/app_mode";
import instance from "./api";

export const sendApiRequest = async (
  obj = null,
  apiMethod,
  method,
  actionType = "",
) => {
  let config;

  let baseURL;
  if (AppMode.isInSandboxMode()) {
    baseURL = process.env.REACT_APP_BASE_URL_SANDBOX;
  } else {
    baseURL = process.env.REACT_APP_BASE_URL_PRODUCTION;
  }
  baseURL =
    apiMethod === "auth/access/token/"
      ? baseURL
      : baseURL + process.env.REACT_APP_BASE_URL_VERSION;

  if (obj) {
    if (actionType !== "multipart") {
      const data = JSON.stringify(obj);
      config = {
        method: method,
        url: `${baseURL}/${apiMethod}`,
        data,
      };
    } else {
      config = {
        method: method,
        url: `${baseURL}/${apiMethod}`,
        data: obj,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
  } else {
    config = {
      method: method,
      url: `${baseURL}/${apiMethod}`,
      data: {},
    };
  }

  const returnOBJ = { error: false, result: {}, status: 0 };

  if (config.method === "get") {
    await instance
      .get(config.url)
      .then(async (response) => {
        returnOBJ.status = response.status;

        if (response.status === 200) {
          returnOBJ.error = false;
          returnOBJ.result = response.data;
        }
      })
      .catch(async (err) => {
        returnOBJ.result = err.response.data;
        returnOBJ.status = err.response.status;
        returnOBJ.error = true;
      });
  } else {
    await instance
      .post(
        config.url,
        config.data,
        config.headers !== null
          ? {
              headers: { ...config.headers },
            }
          : null,
      )
      .then(async (response) => {
        returnOBJ.status = response.status;

        if (response.status === 200 || response.status === 201) {
          returnOBJ.error = false;
          returnOBJ.result = response.data;
        }
      })
      .catch(async (err) => {
        returnOBJ.result = err?.response?.data;
        returnOBJ.status = err?.response?.status;
        returnOBJ.error = true;
      });
  }

  return returnOBJ;
};
