import TokenService from "../../lib/token_service";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  configurationDataFetched: false,
  configurationDataUpdated: false,
  shouldCreateConfigurationData: false,
  shouldNavigateToSignIn: false,
  clientName: "Grow Credit",
  isMockedMode: false,
  applicationUrl: "",
  redirectUrl: "",
  fecthConfigurationInfoError: "",
};

const configurations = createSlice({
  name: "configurations",
  initialState,
  reducers: {
    configurationDataFetched: (state, action) => {
      let configuration = action.payload.result;
      if (action.payload.status === 200 && configuration === false) {
        //Create new Configuration object
        state.shouldCreateConfigurationData = true;
      } else if (action.payload.status === 200) {
        if (
          configuration.configuration !== null &&
          configuration.configuration.clientName != null
        ) {
          const clientName = configuration.configuration?.clientName || "";
          const applicationUrl =
            configuration.configuration?.applicationUrl || "";
          const redirectUrl = configuration.configuration?.redirectUrl || "";
          const isMockedMode =
            configuration.configuration?.isMockedMode || false;

          state.clientName = clientName;
          state.applicationUrl = applicationUrl;
          state.redirectUrl = redirectUrl;
          state.isMockedMode = isMockedMode;
        }

        state.configurationDataFetched = true;
        // state.shouldCreateConfigurationData = false;
      } else if (action.payload.status === 401) {
        //Refresh token is invalid, navigate to Sign-In
        TokenService.updateLocalAccessToken("null");
        TokenService.updateLocalRefreshToken("null");
        state.shouldNavigateToSignIn = true;
      }
      return state;
    },
    configurationDataUpdated: (state, action) => {
      if (action.payload.status === 200 || action.payload.status === 201) {
        state.configurationDataUpdated = true;
      } else {
        state.fecthConfigurationInfoError =
          "Unable to update the configuration information!";
      }
      return state;
    },
    setClientConfiguration: (state, action) => {
      if (action.payload !== null) {
        if (
          action.payload.clientName !== null &&
          action.payload.clientName !== ""
        ) {
          state.clientName = action.payload.clientName;
        }

        if (
          action.payload.isMockedMode !== null &&
          action.payload.isMockedMode !== state.isMockedMode
        ) {
          state.isMockedMode = action.payload.isMockedMode;
        }
      }
      return state;
    },
    reset: (state) => {
      state.configurationDataFetched = false;
      state.configurationDataUpdated = false;
      state.shouldCreateConfigurationData = false;
      state.shouldNavigateToSignIn = false;
      state.fecthConfigurationInfoError = "";
      return state;
    },
  },
});
export const {
  configurationDataFetched,
  configurationDataUpdated,
  setClientConfiguration,
  reset,
} = configurations.actions;
export default configurations.reducer;
