import {
  OnWhitelistingDataCreate,
  OnWhitelistingDataFetched,
  OnWhitelistingDataUpdated,
} from "../slice/whitelisting";
import { call, put, takeEvery } from "redux-saga/effects";

import { WhitelistingActions } from "../types";
import { sendApiRequest } from "../../lib/axios";

function* getWhitelistings(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "whitelisteddomains/fetch/",
    "get",
  );
  yield put(OnWhitelistingDataFetched(result));
}

function* updateWhitelistingDomain(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "whitelisteddomain/update/",
    "post",
  );
  yield put(OnWhitelistingDataUpdated(result));
}

function* createWhitelistingDomain(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "whitelisteddomain/create/",
    "post",
  );
  yield put(OnWhitelistingDataCreate(result));
}

export function* watchWhitelistingAsync() {
  yield takeEvery(
    WhitelistingActions.GET_WHITELISTINGS_ACTION,
    getWhitelistings,
  );
  yield takeEvery(
    WhitelistingActions.ADD_WHITELISTING_DOMAIN_ACTION,
    createWhitelistingDomain,
  );
  yield takeEvery(
    WhitelistingActions.UPDATE_WHITELISTING_DOMAIN_ACTION,
    updateWhitelistingDomain,
  );
}
