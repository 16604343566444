import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  whitelistingDataFetched: false,
  whitelistingDataUpdated: false,
  whitelistingDataCreated: false,
  whitelistingList: [],
  fetchWhitelistingInfoError: "",
  createError: "",
};

const whitelisting = createSlice({
  name: "whitelisting",
  initialState,
  reducers: {
    OnWhitelistingDataFetched: (state, action) => {
      let whitelisting = action.payload.result;

      if (action.payload.status === 200 && whitelisting.result === false) {
        state.whitelistingDataFetched = true;
      } else if (action.payload.status === 200) {
        var whitelistingsListObj = whitelisting;

        if (
          whitelistingsListObj !== null &&
          whitelistingsListObj.length !== 0
        ) {
          state.whitelistingList = [];
          whitelistingsListObj.forEach((element) => {
            state.whitelistingList.push({
              id: element.id,
              domain: element.domain,
              is_active: element.is_active,
            });
          });
        }

        state.whitelistingDataFetched = true;
      } else {
        state.fetchWhitelistingInfoError =
          "Unable to fetch the whitelisted domains from the server!";
      }
      return state;
    },
    OnWhitelistingDataUpdated: (state, action) => {
      if (action.payload.status === 200) {
        state.whitelistingDataUpdated = true;
      } else {
        if (action.payload.result.message !== null) {
          state.createError = action.payload.result.message;
        } else {
          state.createError = "Unable to update the domain!";
        }
      }
      return state;
    },
    OnWhitelistingDataCreate: (state, action) => {
      if (action.payload.status === 200 || action.payload.status === 201) {
        state.whitelistingDataCreated = true;
      } else {
        if (action.payload.result.message !== null) {
          state.createError = action.payload.result.message;
        } else {
          state.createError = "Unable to create the whitelisted domain!";
        }
      }
      return state;
    },
    reset: (state) => {
      state.whitelistingDataFetched = false;
      state.whitelistingDataUpdated = false;
      state.whitelistingDataCreated = false;
      state.fetchWhitelistingInfoError = "";
      state.createError = "";
      return state;
    },
  },
});
export const {
  OnWhitelistingDataFetched,
  OnWhitelistingDataUpdated,
  OnWhitelistingDataCreate,
  reset,
} = whitelisting.actions;
export default whitelisting.reducer;
