import React from "react";
import PropTypes from "prop-types";
import { FormControl, TextField } from "@mui/material";
import { Field, useFormikContext } from "formik";

const InputFieldWithInputProps = (props) => {
  const { handleBlur, setFieldValue } = useFormikContext();
  const {
    name,
    label,
    helperText,
    placeholder,
    size = "medium",
    fullWidth = true,
    variant = "outlined",
    required = false,
    customOnBlur,
    InputProps,
    inputProps,
    handleChange = (event) => {
      setFieldValue(event.target.name, event.target.value);
    },
  } = props;

  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl fullWidth={fullWidth}>
          <TextField
            {...props}
            {...field}
            autoComplete="off"
            name={name}
            fullWidth={fullWidth}
            size={size}
            variant={variant}
            required={required}
            placeholder={placeholder}
            helperText={helperText}
            label={label}
            InputProps={InputProps}
            inputProps={inputProps}
            onChange={handleChange}
            onBlur={(event) => {
              if (customOnBlur) {
                customOnBlur();
              }
              handleBlur(event);
            }}
          />
        </FormControl>
      )}
    </Field>
  );
};

InputFieldWithInputProps.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.any,
  variant: PropTypes.any,
  helperText: PropTypes.any,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default InputFieldWithInputProps;
