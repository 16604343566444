import AppMode from "../components/app_mode";
import TokenService from "./token_service";
import axios from "axios";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    if (AppMode.isInSandboxMode()) {
      config.headers["x-environment"] = "sandbox";
    } else {
      config.headers["x-environment"] = "production";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    var user = await TokenService.getLocalRefreshToken();

    let baseURL;

    if (AppMode.isInSandboxMode()) {
      baseURL = process.env.REACT_APP_BASE_URL_SANDBOX;
    } else {
      baseURL = process.env.REACT_APP_BASE_URL_PRODUCTION;
    }
    baseURL = baseURL + process.env.REACT_APP_BASE_URL_VERSION;
    if (user !== "null" || user !== undefined) {
      if (
        originalConfig.url !== `${baseURL}/auth/access/` &&
        originalConfig.url !== `${baseURL}/auth/refresh/` &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          if (TokenService.getLocalRefreshToken() != null) {
            try {
              const rs = await instance.post(`${baseURL}/auth/refresh/`, {
                refresh: TokenService.getLocalRefreshToken(),
              });

              if (rs.status === 200) {
                const refreshResponse = rs.data;

                TokenService.updateLocalAccessToken(refreshResponse.access);
                TokenService.updateLocalRefreshToken(refreshResponse.refresh);

                originalConfig.headers["Authorization"] =
                  "Bearer " + refreshResponse.access;
                return instance(originalConfig);
              } else {
                TokenService.updateLocalAccessToken("null");
                TokenService.updateLocalRefreshToken("null");
                console.log(`Unexpected response received, go to SignIn. `);
              }

              return instance(originalConfig);
            } catch (_error) {
              console.log(`Unable to get token, go to SignIn. ${_error}`);
              TokenService.updateLocalAccessToken("null");
              TokenService.updateLocalRefreshToken("null");
              return Promise.reject(_error);
            }
          } else {
            TokenService.updateLocalAccessToken("null");
            TokenService.updateLocalRefreshToken("null");
            console.log(`Unable to get token, go to SignIn.`);
          }
        }
      }
    }

    return Promise.reject(err);
  },
);

export default instance;
