import { call, put, takeEvery } from "redux-saga/effects";

import { UserManagement } from "../types";
import { fetch, create, update } from "../slice/users";
import { sendApiRequest } from "../../lib/axios";

function* fetchUsersList(action) {
  try {
    const result = yield call(
      sendApiRequest,
      null,
      `get/users/${action.payload.url}`,
      "post",
    );
    yield put(fetch(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
  }
}
function* CreateUser(action) {
  try {
    const result = yield call(
      sendApiRequest,
      action.payload,
      "user/create/",
      "post",
    );
    yield put(create(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
  }
}
function* updateUser(action) {
  try {
    const result = yield call(
      sendApiRequest,
      action.payload,
      "user/update/",
      "post",
    );
    yield put(update(result));
  } catch (e) {
    console.log(`Error: ${e.message}`);
  }
}

export function* watchUsersAsync() {
  yield takeEvery(UserManagement.FETCH_USERS_ACTION, fetchUsersList);
  yield takeEvery(UserManagement.CREATE_USER_ACTION, CreateUser);
  yield takeEvery(UserManagement.UPDATE_USER_ACTION, updateUser);
}
