import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import CTypography from "components/custom/CTypography";

export const GridContainer = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
}));

export const CenterGridItem = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "15px",
}));
export const PasswordGridItem = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
}));
export const Clear = styled(ClearIcon)(() => ({
  fontSize: "20px",
  color: "#ff726f",
}));
export const Done = styled(DoneIcon)(() => ({
  fontSize: "20px",
  color: "#4BB543",
}));
export const ValidationText = styled(CTypography)(() => ({
  ml: 0.5,
  fontSize: "14px",
}));
