import { createSlice } from "@reduxjs/toolkit";
import { BRANDING_DEFAULT } from "../../theme";

const initialState = {
  shouldCreateBrandingData: false,
  brandingDataFetched: false,
  brandingDataUpdated: false,
  clientLogoUpdated: false,
  countUpdated: 0,
  activeTheme: "",
  themeConfig: null,
  fecthBrandingInfoError: "",
  previewLogoFlag: false,
  previewLogo: "",
  themeType: {
    light: 1,
    dark: 1,
  },
  compareLightOnLoad: true,
  compareDarkOnLoad: true,
  lightTheme: null,
  darkTheme: null
};

const branding = createSlice({
  name: "branding",
  initialState,
  reducers: {
    brandingDataFetched: (state, action) => {
      let brandingResponse = action.payload.result;

      if (action.payload.status === 200 && !brandingResponse?.branding) {
        //Create new branding object
        state.shouldCreateBrandingData = true;
      } else if (
        action.payload.status === 200 ||
        action.payload.status === 201
      ) {
        const themeConfig = brandingResponse.branding;
        let brandingConfig = BRANDING_DEFAULT;
        if (themeConfig != null) {
          brandingConfig = {
            icons: {
              ...brandingConfig.icons,
              ...themeConfig.icons,
            },
            theme: {
              dark: {
                ...brandingConfig.theme.dark,
                ...themeConfig.theme.dark,
              },
              light: {
                ...brandingConfig.theme.light,
                ...themeConfig.theme.light,
              },
              active: themeConfig.theme.active,
            },
          };
        }
        state.themeConfig = brandingConfig;
        state.lightTheme = brandingConfig.theme.light;
        state.darkTheme = brandingConfig.theme.dark;
        state.activeTheme = brandingConfig.theme
          ? brandingConfig.theme.active
          : "light";
        state.previewLogoFlag = brandingConfig.icons.clientLogoDisplay;
        state.previewLogo = brandingConfig.icons.clientLogoUrl;
        state.brandingDataFetched = true;
      }
      return state;
    },
    fetch: (state) => {
      return state;
    },
    brandingDataUpdated: (state, action) => {
      if (action.payload.status === 200 || action.payload.status === 201) {
        state.brandingDataUpdated = true;
        state.countUpdated = state.countUpdated + 1;
      } else {
        state.fecthBrandingInfoError =
          "Unable to update the branding information!";
      }
      return state;
    },
    setColors: (state, action) => {
      if (action.payload != null) {
        if (action.payload.isDarkMode) {
          state.themeConfig.theme.dark = action.payload.dark;
        } else {
          state.themeConfig.theme.light = action.payload.light;
        }
      }
      return state;
    },
    setThemeType: (state, action) => {
      state.themeType[action.payload.label] = action.payload.value;
      if (action.payload.value === 1) {
        state.themeConfig = {
          ...state.themeConfig,
          theme: {
            ...state.themeConfig.theme,
            [action.payload.label]: {
              ...BRANDING_DEFAULT.theme[action.payload.label],
            },
          },
        };
      }
      if (action.payload.value === 2) {
        state.themeConfig = {
          ...state.themeConfig,
          theme: {
            ...state.themeConfig.theme,
            [action.payload.label]:
              action.payload.label === "light"
                ? { ...state.lightTheme }
                : action.payload.label === "dark"
                  ? { ...state.darkTheme }
                  : {
                      ...BRANDING_DEFAULT.theme[action.payload.label],
                    },
          },
        };
      }
      return state;
    },
    isDarkModeSelected: (state, action) => {
      state.themeConfig = {
        ...state.themeConfig,
        theme: {
          ...state.themeConfig.theme,
          active: action.payload ? "dark" : "light",
        },
      };

      return state;
    },
    setProperties: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
      return state;
    },
    uploadClientLogo: (state, action) => {
      if (action.payload.status === 200 || action.payload.status === 201) {
        state.clientLogoUpdated = true;
        if (state.themeConfig === null) {
          let branding = BRANDING_DEFAULT;
          branding = {
            ...branding,
            icons: {
              ...branding.icons,
              clientLogoUrl: action.payload?.result?.url,
            },
          };
          state.themeConfig = branding;
        } else {
          state.themeConfig = {
            ...state.themeConfig,
            icons: {
              ...state.themeConfig.icons,
              clientLogoUrl: action.payload?.result?.url,
            },
          };
        }
      } else {
        state.fecthBrandingInfoError = "Unable to upload the logo";
      }
      return state;
    },
    reset: (state) => {
      state.brandingDataUpdated = false;
      state.brandingDataFetched = false;
      state.clientLogoUpdated = false;
      return state;
    },
  },
});
export const {
  fetch,
  brandingDataUpdated,
  setColors,
  brandingDataFetched,
  isDarkModeSelected,
  setProperties,
  reset,
  uploadClientLogo,
  setThemeType,
} = branding.actions;
export default branding.reducer;
