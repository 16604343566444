import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLoading } from "redux/slice/commonSlice";

const AuthTokenContext = createContext();

export const AuthProvider = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { isValidUser } = useSelector((state) => state.authentication);

  useEffect(() => {
    // const urlRequest = new Request(window.location.href);
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get("token");
    if (value) {
      localStorage.setItem("user_token", window.atob(value));
    }
  }, []);

  useEffect(() => {
    if (isValidUser) {
      setIsAuthenticated(true);
      dispatch(setLoading(false));
    } else if (isValidUser === false) {
      setIsAuthenticated(false);
      dispatch(setLoading(false));
    }
  }, [isValidUser, dispatch]);

  return (
    <AuthTokenContext.Provider
      value={{
        isAuthenticated,
      }}
    >
      {children}
    </AuthTokenContext.Provider>
  );
};

export default AuthTokenContext;
