import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { removeErrorMessage } from "redux/slice/commonSlice";

const CErrorModal = () => {
  const dispatch = useDispatch();
  const { showErrorModal, toastErrorMessage } = useSelector(
    (state) => state.commonReducer,
  );

  const onClose = () => {
    dispatch(removeErrorMessage());
  };

  return (
    <Dialog
      sx={{ borderRadius: "16x" }}
      open={showErrorModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "18px !important",
        }}
        id="alert-dialog-title"
      >
        <div>
          <img
            style={{ width: "40px", height: "40px" }}
            alt="Error icon"
            src="https://growcredit-assets.s3.us-west-2.amazonaws.com/error_icon.png"
          />
        </div>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: { xs: "-4px", md: 8 },
            top: { xs: 4, md: 8 },
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            textAlign: "center",
          }}
        >
          {toastErrorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{"OK"}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CErrorModal;
