import apiLogs from "redux/slice/apilogs";
import authentication from "redux/slice/authentication";
import branding from "redux/slice/branding";
import commonReducer from "redux/slice/commonSlice";
import configurations from "redux/slice/configurations";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import dashboard from "redux/slice/dashboard";
import keys from "redux/slice/keys";
import reports from "redux/slice/reports";
import { rootSaga } from "./redux/sagas";
import webhooks from "redux/slice/webhooks";
import whitelisting from "redux/slice/whitelisting";
import users from "redux/slice/users";
import { combineReducers } from "@reduxjs/toolkit";

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
  authentication,
  branding,
  keys,
  webhooks,
  whitelisting,
  apiLogs,
  reports,
  configurations,
  dashboard,
  commonReducer,
  users,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_REDUX_STORE_ON_LOGOUT") {
    // check for action type
    state = undefined;
    localStorage.clear();
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware,
    ),
});

sagaMiddleware.run(rootSaga);

export default store;
