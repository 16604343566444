import { call, put, takeEvery } from "redux-saga/effects";
import {
  webhooksDataCreate,
  webhooksDataFetched,
  webhooksDataUpdated,
} from "../slice/webhooks";

import { WebHookActions } from "../types";
import { sendApiRequest } from "../../lib/axios";

function* getWebHooks(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "webhook/fetch/",
    "get",
  );
  yield put(webhooksDataFetched(result));
}

function* updateWebHooks(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "webhook/update/",
    "post",
  );
  yield put(webhooksDataUpdated(result));
}

function* createWebHooks(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "webhook/create/",
    "post",
  );
  yield put(webhooksDataCreate(result));
}

export function* watchWebHookAsync() {
  yield takeEvery(WebHookActions.GET_WEBHOOK_ACTION, getWebHooks);
  yield takeEvery(WebHookActions.ADD_WEBHOOK_ACTION, createWebHooks);
  yield takeEvery(WebHookActions.UPDATE_WEBHOOK_ACTION, updateWebHooks);
}
