import { Suspense, lazy } from "react";

import AuthGuard from "./components/custom/AuthGuard";
import LoadingScreen from "./components/LoadingScreen";
import { ProtectedRoute } from "./hoc/ProtectedRoute";
import { MODULES_LIST } from "./constants/Common/roles";

export const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const SignIn = Loadable(lazy(() => import("./pages/SignIn")));
const Branding = Loadable(lazy(() => import("./pages/Branding")));
const Configurations = Loadable(lazy(() => import("./pages/Configurations")));
const Support = Loadable(lazy(() => import("./pages/Support")));
const Reports = Loadable(lazy(() => import("./pages/Reports")));
const APILogs = Loadable(lazy(() => import("./pages/APILogs")));
const Keys = Loadable(lazy(() => import("./pages/Keys")));
const WebHooks = Loadable(lazy(() => import("./pages/Webhooks")));
const ChangeLogs = Loadable(lazy(() => import("./pages/ChangeLogs")));
const WidgetSetup = Loadable(lazy(() => import("./pages/WidgetSetup")));
const WebhooksGuide = Loadable(lazy(() => import("./pages/WebhooksGuide")));
const PWGGuide = Loadable(lazy(() => import("./pages/PwgGuide")));
// const Docs3 = Loadable(lazy(() => import("./pages/Docs2/indexOld.jsx")));
const InviteTeams = Loadable(lazy(() => import("./pages/InviteTeams")));
const ApiDocs = Loadable(lazy(() => import("./pages/APIDocs")));
const Whitelisting = Loadable(lazy(() => import("./pages/Whitelisting")));
const Users = Loadable(lazy(() => import("./pages/Users")));
const RestApi = Loadable(lazy(() => import("./pages/RestApi")));
const ArchivedLogs = Loadable(lazy(() => import("./pages/ArchivedLogs")));
const Releases = Loadable(lazy(() => import("./pages/Releases")));
const AndroidGuide = Loadable(lazy(() => import("./pages/AndroidGuide")));
const DeveloperGuide = Loadable(lazy(() => import("./pages/DeveloperGuide")));

const Logout = Loadable(lazy(() => import("./pages/Logout")));

const routes = [
  {
    path: "config",
    element: (
      <AuthGuard>
        <Configurations />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "branding",
    element: (
      <AuthGuard>
        <Branding />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "support",
    element: (
      <AuthGuard>
        <Support />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "reports",
    element: (
      <AuthGuard>
        <Reports />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "keys",
    element: (
      <AuthGuard>
        <Keys />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "search-logs",
    element: (
      <AuthGuard>
        <APILogs />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "archived-logs",
    element: (
      <AuthGuard>
        <ArchivedLogs />
      </AuthGuard>
    ),
    children: [],
  },

  {
    path: "users",
    element: (
      <AuthGuard>
        <ProtectedRoute permission={MODULES_LIST.USER_MANAGEMENT}>
          <Users />
        </ProtectedRoute>
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "api-docs",
    element: (
      <AuthGuard>
        <ApiDocs />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "widget-setup",
    element: (
      <AuthGuard>
        <WidgetSetup />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "webhooks-guide",
    element: (
      <AuthGuard>
        <WebhooksGuide />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "developer-guide",
    element: (
      <AuthGuard>
        <DeveloperGuide />
      </AuthGuard>
    ),
    children: [],
  },

  {
    path: "pwg-guide",
    element: (
      <AuthGuard>
        <PWGGuide />
      </AuthGuard>
    ),
    children: [],
  },
  // {
  //   path: "apidocs3",
  //   element: (
  //     <AuthGuard>
  //       <Docs3 />
  //     </AuthGuard>
  //   ),
  //   children: [],
  // },
  {
    path: "rest-api",
    element: (
      <AuthGuard>
        <RestApi />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "webhooks",
    element: (
      <AuthGuard>
        <WebHooks />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "change_logs",
    element: (
      <AuthGuard>
        <ChangeLogs />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "invite_teams",
    element: (
      <AuthGuard>
        <InviteTeams />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "whitelisting",
    element: (
      <AuthGuard>
        <Whitelisting />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "*",
    element: (
      <AuthGuard>
        <SignIn />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "signin",
    element: (
      <AuthGuard>
        <SignIn />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "releases",
    element: (
      <AuthGuard>
        <Releases />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "android-guide",
    element: (
      <AuthGuard>
        <AndroidGuide />
      </AuthGuard>
    ),
    children: [],
  },
  {
    path: "logout",
    element: (
      <AuthGuard>
        <Logout />
      </AuthGuard>
    ),
    children: [],
  },
];

export default routes;
