import { createSlice } from "@reduxjs/toolkit";
import { ContactUS } from "constants/Messages";

const initialState = {
  submitTicketSuccess: false,
  submitTicketSuccessMessage: "",
  submitTicketError: false,
  submitTicketErrorMessage: "",
};

const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    submitTicket: (state, action) => {
      let response = action.payload;
      if (response.status === 200) {
        let res = response.result;
        if (res.result) {
          state.submitTicketSuccess = true;
          state.submitTicketSuccessMessage = res.message;
        } else {
          state.submitTicketError = true;
          state.submitTicketErrorMessage =
            ContactUS.ERROR_SUBMIT_TICkET_MESSAGE;
        }
      } else {
        state.submitTicketError = true;
        state.submitTicketErrorMessage = ContactUS.ERROR_SUBMIT_TICkET_MESSAGE;
      }
      return state;
    },
    resetTicketSubmission: (state) => {
      state.submitTicketSuccess = false;
      state.submitTicketSuccessMessage = "";
      state.submitTicketError = false;
      state.submitTicketErrorMessage = "";
      return state;
    },
  },
});
export const { submitTicket, resetTicketSubmission } = dashboard.actions;
export default dashboard.reducer;
