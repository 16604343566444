import {
  Archive,
  Code,
  ExpandLess,
  ExpandMore,
  Search,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { ThemeProvider, styled } from "@mui/material/styles";

import AddModeratorIcon from "@mui/icons-material/AddModerator";
import Source from "@mui/icons-material/Source";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BusinessIcon from "@mui/icons-material/Business";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import ApiIcon from "@mui/icons-material/Api";
import { Collapse } from "@mui/material";
import { Container } from "@mui/system";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import Divider from "@mui/material/Divider";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GridViewIcon from "@mui/icons-material/GridView";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyIcon from "@mui/icons-material/Key";
import List from "@mui/material/List";
import { ListItem } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportIcon from "@mui/icons-material/Support";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import WebhookIcon from "@mui/icons-material/Webhook";
import drawerTheme from "../theme/drawer_theme";
import { useNavigate } from "react-router";
import { usePermissions } from "../hooks/usePermissions";
import { MODULES_LIST } from "../constants/Common/roles";

const getMenuItemList = (hasPermission) => [
  {
    key: "home",
    name: "Home",
    icon: <GridViewIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: false,
  },
  {
    key: "brand",
    name: "Branding",
    icon: <BusinessIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: true,
  },
  {
    key: "widget",
    name: "Configurations",
    icon: <ToggleOnIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: true,
  },
  {
    key: "whitelisting",
    name: "Domain Whitelisting",
    icon: <AddModeratorIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: true,
  },
  {
    key: "divider_1",
    name: "",
    icon: <Divider />,
    isSection: false,
    sectionName: "",
    isDivider: true,
    childItems: [],
    isEnabled: true,
  },
  {
    key: "support",
    name: "Support",
    icon: <SupportIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: false,
  },
  {
    key: "reports",
    name: "Reports",
    icon: <AssessmentIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: false,
  },
  {
    key: "divider_1",
    name: "",
    icon: <Divider />,
    isSection: false,
    sectionName: "",
    isDivider: true,
    childItems: [],
    isEnabled: false,
  },
  {
    key: "keys",
    name: "Keys",
    icon: <KeyIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: true,
  },
  {
    key: "webhooks",
    name: "Webhooks",
    icon: <WebhookIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: true,
  },
  {
    key: "section_1",
    name: "",
    icon: <Divider />,
    isSection: true,
    sectionName: "Application",
    isDivider: false,
    isEnabled: false,
    childItems: [
      {
        key: "keys",
        name: "Keys",
        icon: <KeyIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
      {
        key: "webhooks",
        name: "Webhooks",
        icon: <WebhookIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
    ],
  },
  {
    key: "section_3",
    name: "",
    isSection: true,
    sectionName: "API Logs",
    isDivider: false,
    childItems: [
      {
        key: "logs_search",
        name: "Search",
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
      {
        key: "logs_archived",
        name: "Archived",
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
    ],
    isEnabled: true,
  },
  {
    key: "change_logs",
    name: "Docs",
    icon: <Source />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: false,
  },
  {
    key: "api_docs",
    name: "Quick Guide",
    icon: <IntegrationInstructionsIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: false,
  },
  {
    key: "section_2",
    name: "",
    icon: <Divider />,
    isSection: true,
    sectionName: "Docs",
    isDivider: false,
    isEnabled: true,
    childItems: [
      {
        key: "pwg-guide",
        name: "PWG Guide",
        icon: <AccountTreeIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
      {
        key: "api_docs",
        name: "Widget Setup",
        icon: <IntegrationInstructionsIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
      {
        key: "webhooks_docs",
        name: "Webhooks Guide",
        icon: <WebhookIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
      {
        key: "develeoper_guide",
        name: "Develeoper Guide",
        icon: <Code />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
    ],
  },
  {
    key: "divider_2",
    name: "",
    icon: <Divider />,
    isSection: false,
    sectionName: "",
    isDivider: true,
    childItems: [],
    isEnabled: true,
  },
  {
    key: "section_4",
    name: "",
    isSection: true,
    sectionName: "Swagger",
    isDivider: false,
    childItems: [
      {
        key: "change_logs",
        name: "API Docs",
        icon: <TextSnippetIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
      {
        key: "rest_api",
        name: "Rest API",
        icon: <ApiIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
    ],
    isEnabled: true,
  },
  {
    key: "section_5",
    name: "",
    isSection: true,
    sectionName: "Mobile SDKs",
    isDivider: false,
    childItems: [
      {
        key: "android_guide",
        name: "Android SDK",
        icon: <TextSnippetIcon />,
        isSection: false,
        sectionName: "",
        isDivider: false,
        childItems: [],
        isEnabled: true,
      },
    ],
    isEnabled: true,
  },
  {
    key: "invite_teams",
    name: "User Management",
    icon: <GroupsIcon />,
    isSection: false,
    sectionName: "",
    isDivider: false,
    childItems: [],
    isEnabled: hasPermission(MODULES_LIST.USER_MANAGEMENT),
  },
];

export default function MenuItems() {
  const navListRef = React.useRef(null);
  const [selectedPage, setSelectedPage] = useState("");
  const [selectedSection, setSelectedSection] = useState("");

  const [openCollapse0, setOpenCollapse0] = useState(false);
  const [openCollapse1, setOpenCollapse1] = useState(false);
  const [openCollapse2, setOpenCollapse2] = useState(false);
  const [openCollapse3, setOpenCollapse3] = useState(false);
  const [openCollapse4, setOpenCollapse4] = useState(false);
  const [openCollapse5, setOpenCollapse5] = useState(false);
  const { hasPermission } = usePermissions();

  useEffect(() => {
    const setNavMaxHeight = () => {
      navListRef.current.style.maxHeight = `${window.innerHeight - 68}px`;
    };

    setNavMaxHeight();
    window.addEventListener("resize", setNavMaxHeight);
    return () => {
      window.removeEventListener("resize", setNavMaxHeight);
    };
  }, []);

  function handleOpenSettings(key) {
    if (key === "section_0") {
      setSelectedSection("section_0");
      setOpenCollapse0(!openCollapse0);
    } else if (key === "section_1") {
      setSelectedSection("section_1");
      setOpenCollapse1(!openCollapse1);
    } else if (key === "section_2") {
      setOpenCollapse2(!openCollapse2);
    } else if (key === "section_3") {
      setOpenCollapse3(!openCollapse3);
    } else if (key === "section_4") {
      setOpenCollapse4(!openCollapse4);
    } else if (key === "section_5") {
      setOpenCollapse5(!openCollapse5);
    }
  }

  const handleClick = (key, isSection) => {
    setSelectedPage(key);
    goToPage(key);

    if (!isSection) {
      setSelectedSection("");
    }
  };

  const navigate = useNavigate();

  const goToPage = (page) => {
    if (page === "home") {
      navigate("/dashboard", { replace: true, state: {} });
    } else if (page === "widget") {
      navigate("/config", { replace: true, state: {} });
    } else if (page === "brand") {
      navigate("/branding", { replace: true, state: {} });
    } else if (page === "support") {
      navigate("/support", { replace: true, state: {} });
    } else if (page === "reports") {
      navigate("/reports", { replace: true, state: {} });
    } else if (page === "keys") {
      navigate("/keys", { replace: true, state: {} });
    } else if (page === "logs_search") {
      navigate("search-logs", { replace: true, state: {} });
    } else if (page === "logs_archived") {
      navigate("/archived-logs", { replace: true, state: {} });
    } else if (page === "webhooks") {
      navigate("/webhooks", { replace: true, state: {} });
    } else if (page === "change_logs") {
      navigate("/api-docs", { replace: true, state: {} });
    } else if (page === "api_docs") {
      navigate("/widget-setup", { replace: true, state: {} });
    } else if (page === "invite_teams") {
      navigate("/users", { replace: true, state: {} });
    } else if (page === "whitelisting") {
      navigate("/whitelisting", { replace: true, state: {} });
    } else if (page === "rest_api") {
      navigate("/rest-api", { replace: true, state: {} });
    } else if (page === "pwg-guide") {
      navigate("/pwg-guide", { replace: true, state: {} });
    } else if (page === "webhooks_docs") {
      navigate("/webhooks-guide", { replace: true, state: {} });
    } else if (page === "android_guide") {
      navigate("/android-guide", { replace: true, state: {} });
    } else if (page === "develeoper_guide") {
      navigate("/developer-guide", { replace: true, state: {} });
    }
  };

  const ListItemComponent = styled("div")((props) => ({
    backgroundColor: props.myBackgroundColor,
  }));
  const StyledCollapse = styled(Collapse)(() => ({
    paddingLeft: "20px",
    paddingRight: "5px",
  }));
  const menuItemList = getMenuItemList(hasPermission);

  const mainListItems = (
    <React.Fragment>
      {menuItemList.map((item) => {
        if (!item.isEnabled) return null;
        if (item.isDivider === true) return <Container key={item.key} />;
        else if (item.isSection === true) {
          if (item.childItems.length > 0) {
            return (
              <List key={item.key}>
                <ListItem
                  key={item.key}
                  onClick={() => handleOpenSettings(item.key)}
                  sx={{
                    backgroundColor:
                      selectedSection === item.key ? "lightGray" : "action",
                  }}
                >
                  {item.icon}
                  <ListItemIcon
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {item.key === "section_0" && (
                      <SettingsIcon
                        color={selectedPage === item.key ? "action" : "primary"}
                      />
                    )}
                    {item.key === "section_1" && (
                      <DashboardCustomizeIcon
                        color={selectedPage === item.key ? "action" : "primary"}
                      />
                    )}
                    {item.key === "section_2" && (
                      <Source
                        color={selectedPage === item.key ? "action" : "primary"}
                      />
                    )}
                    {item.key === "section_3" && (
                      <AssignmentIcon
                        color={selectedPage === item.key ? "action" : "primary"}
                      />
                    )}
                    {item.key === "section_4" && (
                      <AssignmentIcon
                        color={selectedPage === item.key ? "action" : "primary"}
                      />
                    )}
                    {item.key === "section_5" && (
                      <SmartphoneIcon
                        color={selectedPage === item.key ? "action" : "primary"}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.sectionName}
                    sx={{
                      cursor: "pointer",
                      color: selectedPage === item.key ? "action" : "black",
                    }}
                  />
                  {item.key === "section_0" &&
                    (openCollapse0 ? (
                      <ExpandLess
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  {item.key === "section_1" &&
                    (openCollapse1 ? (
                      <ExpandLess
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  {item.key === "section_2" &&
                    (openCollapse2 ? (
                      <ExpandLess
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  {item.key === "section_3" &&
                    (openCollapse3 ? (
                      <ExpandLess
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  {item.key === "section_4" &&
                    (openCollapse4 ? (
                      <ExpandLess
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  {item.key === "section_5" &&
                    (openCollapse5 ? (
                      <ExpandLess
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                </ListItem>
                {item.key === "section_0" && (
                  <StyledCollapse
                    in={openCollapse0}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {item.childItems.map((childItem) => {
                        if (!childItem.isEnabled) return null;
                        return (
                          <ListItemComponent
                            key={childItem.key}
                            onClick={() => handleClick(childItem.key, true)}
                            sx={{
                              backgroundColor:
                                selectedPage === childItem.key
                                  ? "lightGray"
                                  : "action",
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                {childItem.key === "widget" && (
                                  <ToggleOnIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                                {childItem.key === "whitelisting" && (
                                  <AddModeratorIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={childItem.name}
                                sx={{
                                  color:
                                    selectedPage === childItem.key
                                      ? "action"
                                      : "black",
                                }}
                              />
                            </ListItemButton>
                          </ListItemComponent>
                        );
                      })}
                      <Divider key={item.key + "_section_2"} />
                    </List>
                  </StyledCollapse>
                )}
                {item.key === "section_1" && (
                  <StyledCollapse
                    in={openCollapse1}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {item.childItems.map((childItem) => {
                        if (!childItem.isEnabled) return null;
                        return (
                          <ListItemComponent
                            key={childItem.key}
                            onClick={() => handleClick(childItem.key, true)}
                            sx={{
                              backgroundColor:
                                selectedPage === childItem.key
                                  ? "lightGray"
                                  : "action",
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                {childItem.key === "keys" && (
                                  <KeyIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                                {childItem.key === "webhooks" && (
                                  <WebhookIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={childItem.name}
                                sx={{
                                  color:
                                    selectedPage === childItem.key
                                      ? "action"
                                      : "black",
                                }}
                              />
                            </ListItemButton>
                          </ListItemComponent>
                        );
                      })}
                      <Divider key={item.key + "_section_2"} />
                    </List>
                  </StyledCollapse>
                )}
                {item.key === "section_2" && (
                  <StyledCollapse
                    in={openCollapse2}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {item.childItems.map((childItem) => {
                        if (!childItem.isEnabled) return null;
                        return (
                          <ListItemComponent
                            key={childItem.key}
                            onClick={() => handleClick(childItem.key, true)}
                            sx={{
                              backgroundColor:
                                selectedPage === childItem.key
                                  ? "lightGray"
                                  : "action",
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                {childItem.key === "pwg-guide" && (
                                  <AccountTreeIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                                {childItem.key === "webhooks_docs" && (
                                  <WebhookIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                                {childItem.key === "develeoper_guide" && (
                                  <Code
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                                {childItem.key === "api_docs" && (
                                  <IntegrationInstructionsIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={childItem.name}
                                sx={{
                                  color:
                                    selectedPage === childItem.key
                                      ? "action"
                                      : "black",
                                }}
                              />
                            </ListItemButton>
                          </ListItemComponent>
                        );
                      })}
                    </List>
                  </StyledCollapse>
                )}
                {item.key === "section_3" && (
                  <StyledCollapse
                    in={openCollapse3}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {item.childItems.map((childItem) => {
                        if (!childItem.isEnabled) return null;
                        return (
                          <ListItemComponent
                            key={childItem.key}
                            onClick={() => handleClick(childItem.key, true)}
                            sx={{
                              backgroundColor:
                                selectedPage === childItem.key
                                  ? "lightGray"
                                  : "action",
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                {childItem.key === "logs_search" && (
                                  <Search
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                                {childItem.key === "logs_archived" && (
                                  <Archive
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={childItem.name}
                                sx={{
                                  color:
                                    selectedPage === childItem.key
                                      ? "action"
                                      : "black",
                                }}
                              />
                            </ListItemButton>
                          </ListItemComponent>
                        );
                      })}
                    </List>
                  </StyledCollapse>
                )}
                {item.key === "section_4" && (
                  <StyledCollapse
                    in={openCollapse4}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {item.childItems.map((childItem) => {
                        if (!childItem.isEnabled) return null;
                        return (
                          <ListItemComponent
                            key={childItem.key}
                            onClick={() => handleClick(childItem.key, true)}
                            sx={{
                              backgroundColor:
                                selectedPage === childItem.key
                                  ? "lightGray"
                                  : "action",
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                {childItem.key === "change_logs" && (
                                  <TextSnippetIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}

                                {childItem.key === "rest_api" && (
                                  <ApiIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={childItem.name}
                                sx={{
                                  color:
                                    selectedPage === childItem.key
                                      ? "action"
                                      : "black",
                                }}
                              />
                            </ListItemButton>
                          </ListItemComponent>
                        );
                      })}
                    </List>
                  </StyledCollapse>
                )}
                {item.key === "section_5" && (
                  <StyledCollapse
                    in={openCollapse5}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      {item.childItems.map((childItem) => {
                        if (!childItem.isEnabled) return null;
                        return (
                          <ListItemComponent
                            key={childItem.key}
                            onClick={() => handleClick(childItem.key, true)}
                            sx={{
                              backgroundColor:
                                selectedPage === childItem.key
                                  ? "lightGray"
                                  : "action",
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon>
                                {childItem.key === "android_guide" && (
                                  <TextSnippetIcon
                                    color={
                                      selectedPage === childItem.key
                                        ? "action"
                                        : "primary"
                                    }
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={childItem.name}
                                sx={{
                                  color:
                                    selectedPage === childItem.key
                                      ? "action"
                                      : "black",
                                }}
                              />
                            </ListItemButton>
                          </ListItemComponent>
                        );
                      })}
                    </List>
                  </StyledCollapse>
                )}
              </List>
            );
          } else {
            return (
              <ListItemComponent key={item.key}>
                {item.icon}
                <ListSubheader component="div" inset>
                  {item.sectionName}
                </ListSubheader>
                <Divider />
              </ListItemComponent>
            );
          }
        } else if (item.isSection === false) {
          return (
            <ListItemComponent
              key={item.key}
              onClick={() => handleClick(item.key, false)}
              sx={{
                backgroundColor:
                  selectedPage === item.key ? "lightGray" : "action",
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  {item.key === "home" && (
                    <GridViewIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "widget" && (
                    <ToggleOnIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "whitelisting" && (
                    <AddModeratorIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "brand" && (
                    <BusinessIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "support" && (
                    <SupportIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "reports" && (
                    <AssessmentIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "keys" && (
                    <KeyIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}

                  {item.key === "change_logs" && (
                    <Source
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "api_docs" && (
                    <IntegrationInstructionsIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "pwg-guide" && (
                    <AccountTreeIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}

                  {item.key === "webhooks" && (
                    <WebhookIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "webhooks_docs" && (
                    <WebhookIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "develeoper_guide" && (
                    <Code
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}

                  {item.key === "invite_teams" && (
                    <GroupsIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                  {item.key === "android_guide" && (
                    <IntegrationInstructionsIcon
                      color={selectedPage === item.key ? "action" : "primary"}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ color: selectedPage === item.key ? "action" : "black" }}
                />
              </ListItemButton>
            </ListItemComponent>
          );
        } else return null;
      })}
    </React.Fragment>
  );

  return (
    <ThemeProvider theme={drawerTheme}>
      <List
        component="nav"
        ref={navListRef}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {mainListItems}
      </List>
    </ThemeProvider>
  );
}
