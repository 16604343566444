import { createSlice } from "@reduxjs/toolkit";
import { Users } from "constants/Messages";

const initialState = {
  UserList: [],
  fetchUsersSuccess: false,
  nextPage: "",
  previousPage: "",
  pageCount: 0,
  currentPage: 0,
  fetchUserError: false,
  fetchUserErrorMessage: "",
  createUsersSuccess: false,
  createUsersSuccessMessage: "",
  createUserError: false,
  createUserErrorMessage: "",
  updateUsersSuccess: false,
  updateUsersSuccessMessage: "",
  updateUserError: false,
  updateUserErrorMessage: "",
};

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetch: (state, action) => {
      let response = action.payload;
      if (response.status === 200) {
        state.fetchUsersSuccess = true;
        let usersdata = response.result.results;
        usersdata.forEach((obj) => {
          obj.request_body = JSON.stringify(obj.request_body);
          let date = new Date(obj.date_joined);
          obj.date_joined =
            date.toLocaleDateString("en-US") +
            " " +
            ((date.getHours() + 24) % 12 || 12) +
            ":" +
            date.getMinutes().toString();
        });
        state.UserList = usersdata;
        state.nextPage = response.result.next;
        state.previousPage = response.result.previous;
        state.currentPage = response.result.current;
        state.pageCount = response.result.lastPage;
      } else {
        state.fetchUserError = true;
        state.fetchUserErrorMessage = Users.ERROR_FETCH_USERS_MESSAGE;
      }
      return state;
    },
    create: (state, action) => {
      let response = action.payload;
      if (response.status === 201) {
        let usersdata = response.result;
        usersdata.request_body = JSON.stringify(usersdata.request_body);
        let date = new Date(usersdata.date_joined);
        usersdata.date_joined =
          date.toLocaleDateString("en-US") +
          " " +
          ((date.getHours() + 24) % 12 || 12) +
          ":" +
          date.getMinutes().toString();
        let userList = JSON.parse(JSON.stringify(state.UserList));
        const newUsers = [...userList, usersdata];
        state.UserList = newUsers;
        state.createUsersSuccessMessage = Users.SUCCESS_CREATE_USERS_MESSAGE;
        state.createUsersSuccess = true;
      } else {
        if (!response.result.result && response.result.message) {
          state.createUserErrorMessage = response.result.message;
        } else {
          state.createUserErrorMessage = Users.ERROR_CREATE_USERS_MESSAGE;
        }
        state.createUserError = true;
      }
      return state;
    },
    update: (state, action) => {
      let response = action.payload;
      if (response.status === 200) {
        let res = response.result;
        if (res.result) {
          state.updateUsersSuccess = true;
          state.updateUsersSuccessMessage = res.message;
        } else {
          state.updateUserError = true;
          state.updateUserErrorMessage = Users.ERROR_UPDATE_USERS_MESSAGE;
        }
      } else {
        state.updateUserError = true;
        state.updateUserErrorMessage = Users.ERROR_UPDATE_USERS_MESSAGE;
      }
      return state;
    },
    resetCreateUpdateUser: (state) => {
      state.createUsersSuccess = false;
      state.createUsersSuccessMessage = "";
      state.createUserError = false;
      state.createUserErrorMessage = "";
      state.updateUsersSuccess = false;
      state.updateUsersSuccessMessage = "";
      state.updateUserError = false;
      state.updateUserErrorMessage = "";

      return state;
    },
  },
});
export const { fetch, create, update, resetCreateUpdateUser } = users.actions;
export default users.reducer;
