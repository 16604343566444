import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";

export const BRANDING_DEFAULT = {
  icons: {
    gcLogoUrl: "",
    clientLogoUrl: "",
    gcLogoDisplay: false,
    clientLogoDisplay: false,
  },
  theme: {
    dark: {
      body: "#22253f",
      button: "#7077f3",
      buttonText: "#ffffff",
      primary: "#fcfcfc",
      link: "#7077f3",
      input: "#ffffff",
    },
    light: {
      body: "#ffffff",
      button: "#6b35ee",
      primary: "#182142",
      buttonText: "#ffffff",
      link: "#6b35ee",
      input: "#182142",
    },
    active: "light",
  },
};

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
};

export const themeConfig = {
  body: "#ffffff",
  button: "#a80888",
  primary: "#2e2d2d",
  buttonText: "#fdfafa",
};

export const GlobalStyled = ({ config }) => {
  return (
    <GlobalStyles
      styles={{
        "::selection": {
          color: config.buttonText,
          backgroundColor: config.button,
        },
        body: {
          color: hexToRGB(config.primary, "0.87"),
        },
      }}
    />
  );
};

const theme = responsiveFontSizes(
  createTheme({
    spacing: 8,
    shape: {
      borderRadius: 6,
    },
    typography: {
      fontFamily: ["Lato", "Roboto", "Raleway", "Open Sans"].join(","),
      h1: {
        fontSize: "2rem",
        fontWeight: 500,
        color: "black",
        margin: "8px 0 16px",
      },
      h2: {
        fontSize: "1.75rem",
        fontWeight: 500,
        color: "black",
        margin: "16px 0 8px",
      },
      h3: {
        fontSize: "1.5rem",
        fontWeight: 500,
        color: "black",
        margin: "16px 0 8px",
      },
      button: {
        textTransform: "none",
      },
    },
    palette: {
      mode: "light",
      background: {
        default: "#fff",
      },
      primary: {
        main: "#28335B",
      },
      secondary: {
        main: "#28335B",
      },
      disabled: {
        main: "#fff",
      },
      text: {
        primary: "#4c505c",
        secondary: "#4c505c",
        disabled: "#28335B",
        light: "rgba(24, 33, 66, 0.06)",
        button: "#6B35EE",
      },
    },
  }),
);

export default theme;
