import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { setLoading, showErrormessage } from "redux/slice/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import FormikControl from "components/custom/formik/FormikControl.jsx";
import { GridContainer, GridItem } from "./styles.js";
import CButton from "components/custom/CButton";
import { ContactUsScreen } from "constants/Contents";
import { ContactUsActions } from "redux/types";
import toast from "react-hot-toast";
import { resetTicketSubmission } from "redux/slice/dashboard.js";

const ContactUs = (props) => {
  const { handleCloseInfo } = props;
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const {
    submitTicketSuccess,
    submitTicketSuccessMessage,
    submitTicketError,
    submitTicketErrorMessage,
  } = useSelector((state) => state.dashboard);
  useEffect(() => {
    if (submitTicketSuccess) {
      handleCloseInfo();

      dispatch(setLoading(false));
      toast.success(submitTicketSuccessMessage, {
        id: "success1",
      });
      dispatch(resetTicketSubmission());
    }
  }, [
    submitTicketSuccess,
    submitTicketSuccessMessage,
    dispatch,
    handleCloseInfo,
  ]);
  useEffect(() => {
    if (submitTicketError) {
      dispatch(setLoading(false));
      dispatch(showErrormessage(submitTicketErrorMessage));
      dispatch(resetTicketSubmission());
    }
  }, [submitTicketError, dispatch, submitTicketErrorMessage]);
  const formInitialValue = {
    coments: "",
  };
  const validationSchemaModel = Yup.object().shape({
    coments: Yup.string().required(ContactUsScreen.REQUIRED_FEEDBACK_ERROR),
  });
  const handleSubmit = (values) => {
    dispatch(setLoading(true));
    dispatch({
      type: ContactUsActions.SUBMIT_TICKET_ACTION,
      payload: { feedback: values.coments },
    });
  };
  return (
    <GridContainer container>
      <GridItem item xs={12}>
        <Formik
          initialValues={formInitialValue}
          validationSchema={validationSchemaModel}
          innerRef={formikRef}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ touched, errors, getFieldProps }) => (
            <Form id="contactUs">
              <Grid container>
                <Grid item xs={12}>
                  <FormikControl
                    control="Textarea"
                    label={ContactUsScreen.LABEL}
                    name="coments"
                    multiline
                    rows={10}
                    required
                    fullWidth
                    handleChange={(event) => {
                      formikRef.current.setFieldValue(
                        "coments",
                        event.target.value,
                      );
                    }}
                    error={Boolean(touched.coments && errors.coments)}
                    helperText={touched.coments && errors.coments}
                    {...getFieldProps("coments")}
                  />
                </Grid>
                <GridItem item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                  <CButton size="large" variant="contained" type="submit">
                    {ContactUsScreen.BUTTON}
                  </CButton>
                </GridItem>
              </Grid>
            </Form>
          )}
        </Formik>
      </GridItem>
    </GridContainer>
  );
};
export default ContactUs;
