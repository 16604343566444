import { Field, useFormikContext } from "formik";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import PropTypes from "prop-types";
import React from "react";

const SelectField = (props) => {
  const { setFieldValue } = useFormikContext();
  const {
    name,
    label,
    helperText,
    placeholder,
    size = "medium",
    fullWidth = true,
    variant = "outlined",
    required = false,
    options,
    disabled,
    addNone = false,
    readOnly = false,
    handleChange = (event) => {
      setFieldValue(event.target.name, event.target.value);
    },
  } = props;
  return (
    <Field as="Select" name={name}>
      {({ field, form }) => (
        <FormControl
          fullWidth={fullWidth}
          error={form.errors[name] && form.touched[name]}
          required={required}
        >
          <InputLabel id={`select-${name}`}>{label}</InputLabel>

          <Select
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            inputProps={{
              sx: {
                bgcolor: "text.light",
                borderRadius: "6px",
              },
            }}
            readOnly={readOnly}
            disabled={disabled}
            labelId={`select-labelId-${name}`}
            id={`select-${name}`}
            {...props}
            {...field}
            name={name}
            autoComplete="off"
            fullWidth={fullWidth}
            size={size}
            variant={variant}
            required={required}
            placeholder={placeholder}
            label={label}
            // MenuProps={MenuProps}
            onChange={handleChange}
          >
            {addNone && <MenuItem value="None">None</MenuItem>}

            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      )}
    </Field>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.any,
  size: PropTypes.any,
  variant: PropTypes.any,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.array,
  addNone: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default SelectField;
