import React, { useCallback, useEffect, useState } from "react";

import TokenService from "../../lib/token_service";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";

const AuthGuard = (props) => {
  const { children } = props;
  const [status, setStatus] = useState(false);
  const [shouldNavigateToDashboard, setShouldNavigateToDashboard] =
    useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const checkToken = useCallback(async () => {
    try {
      var user = TokenService.getLocalAccessToken();
      if (user === "null" || user === undefined) {
        console.log(`Not allowed: Navivate to sign in page.`);
        navigate(`/signin`);
      } else if (
        location.pathname !== "/config" &&
        (location.pathname === "/signin" || location.pathname === "/") &&
        user !== "null"
      ) {
        console.log(`Allowed: Navivate to home page.`);
        setShouldNavigateToDashboard(true);
      }
      setStatus(true);
    } catch (error) {
      //navigate(`/signin`);
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    setShouldNavigateToDashboard(false);
    checkToken();
  }, [children, checkToken]);

  if (shouldNavigateToDashboard) {
    navigate("/config", { replace: true, state: {} });
    return <React.Fragment></React.Fragment>;
  } else {
    return status ? (
      <React.Fragment>{children}</React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  }
};

export default AuthGuard;
