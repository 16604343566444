import { createSlice } from "@reduxjs/toolkit";
import { ApiLogs } from "constants/Messages";

const initialState = {
  logs: [],
  fetchLogSuccess: false,
  nextPage: "",
  previousPage: "",
  pageCount: 0,
  currentPage: 0,
  fetchLogError: false,
  fetchLogErrorMessage: "",
  archivedLogs: [],
  fetchArchivedLogSuccess: false,
  nextPageArchived: "",
  previousPageArchived: "",
  pageCountArchived: 0,
  currentPageArchived: 0,
  fetchArchivedLogError: false,
  fetchArchivedLogErrorMessage: "",
  exportLogsCSV: [],
  fetchExportLog: false,
  exportLogError: false,
  exportLogErrorMessage: "",
  apiDocsData: "",
  fetchApiDoc: false,
  fetchApiDocErr: false,
  fetchApiDocErrMsg: "",
};

const apiLogs = createSlice({
  name: "apiLogs",
  initialState,
  reducers: {
    fetch: (state, action) => {
      let response = action.payload;
      if (response.status === 200) {
        state.fetchLogSuccess = true;
        let apiLogsData = response.result.results;
        apiLogsData.forEach((obj) => {
          obj.request_body = JSON.stringify(obj.request_body);
          let date = new Date(obj.created_at);
          obj.created_at =
            date.toLocaleDateString("en-US") +
            " " +
            ((date.getHours() + 24) % 12 || 12) +
            ":" +
            date.getMinutes().toString();
        });
        state.logs = apiLogsData;
        state.nextPage = response.result.next;
        state.previousPage = response.result.previous;
        state.currentPage = response.result.current;
        state.pageCount = response.result.lastPage;
      } else {
        state.fetchLogError = true;
        state.fetchLogErrorMessage = ApiLogs.ERROR_FETCH_LOG_MESSAGE;
      }
      return state;
    },
    fetchArchivedLogs: (state, action) => {
      let response = action.payload;

      if (response.status === 200) {
        state.fetchArchivedLogSuccess = true;
        let apiLogsData = [];
        apiLogsData = response.result.results;
        if (apiLogsData.length > 0) {
          apiLogsData.forEach((obj) => {
            obj.request_body = JSON.stringify(obj.request_body);
            let date = new Date(obj.created_at);
            obj.created_at =
              date.toLocaleDateString("en-US") +
              " " +
              ((date.getHours() + 24) % 12 || 12) +
              ":" +
              date.getMinutes().toString();
          });
        }
        state.archivedLogs = apiLogsData;
        state.nextPageArchived = response.result.next;
        state.previousPageArchived = response.result.previous;
        state.currentPageArchived = response.result.current;
        state.pageCountArchived = response.result.lastPage;
      } else {
        state.fetchArchivedLogError = true;
        state.fetchArchivedLogErrorMessage =
          ApiLogs.ERROR_FETCH_ARCHIVED_LOG_MESSAGE;
      }
      return state;
    },
    fetchDownloadLogs: (state, action) => {
      let response = action.payload;
      if (response.status === 200) {
        let apiLogsData = response.result;
        state.exportLogsCSV = apiLogsData;
        state.fetchExportLog = true;
      } else {
        state.exportLogError = true;
        state.exportLogErrorMessage = ApiLogs.ERROR_EXPORT_LOG_MESSAGE;
      }
      return state;
    },
    fetchApiDoc: (state, action) => {
      let response = action.payload;
      if (response.status === 200) {
        state.apiDocsData = response.result;
        state.fetchApiDoc = true;
      } else {
        state.fetchApiDocErr = true;
        state.fetchApiDocErrMsg = ApiLogs.ERROR_FETCH_API_DOCS_MESSAGE;
      }
      return state;
    },
    resetAPIDocs: (state) => {
      state.fetchApiDoc = false;
      state.fetchApiDocErr = false;
      state.fetchApiDocErrMsg = "";
      return state;
    },
    reset: (state) => {
      state.fetchLogSuccess = false;
      state.fetchLogError = false;
      state.fetchLogErrorMessage = "";
      state.fetchExportLog = false;
      state.exportLogError = false;
      state.exportLogErrorMessage = "";
      return state;
    },
  },
});
export const {
  fetch,
  fetchDownloadLogs,
  fetchApiDoc,
  reset,
  resetAPIDocs,
  fetchArchivedLogs,
} = apiLogs.actions;
export default apiLogs.reducer;
