import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  webhooksDataFetched: false,
  webhooksDataUpdated: false,
  webhooksDataCreated: false,
  webhooksList: [],
  pwgWebhooksList: [],
  fetchWebhooksInfoError: "",
};

const webhooks = createSlice({
  name: "webhooks",
  initialState,
  reducers: {
    webhooksDataFetched: (state, action) => {
      const webhooks = action.payload.result;

      if (action.payload.status === 200 && webhooks.result === false) {
        state.webhooksDataFetched = true;
      } else if (action.payload.status === 200) {
        const { webhook, pwg_webhook } = webhooks;
        state.webhooksList = webhook;
        state.pwgWebhooksList = pwg_webhook;
        state.webhooksDataFetched = true;
      } else {
        state.fetchWebhooksInfoError =
          "Unable to fetch the webhooks information from the server!";
      }
      return state;
    },
    webhooksDataUpdated: (state, action) => {
      if (action.payload.status === 200) {
        state.webhooksDataUpdated = true;
      } else {
        state.fetchWebhooksInfoError = "Unable to update the webhook!";
      }
      return state;
    },
    webhooksDataCreate: (state, action) => {
      if (action.payload.status === 200 || action.payload.status === 201) {
        state.webhooksDataCreated = true;
      } else {
        if (action.payload.result.message !== null) {
          state.fetchWebhooksInfoError = action.payload.result.message;
        } else {
          state.fetchWebhooksInfoError = "Unable to create the webhook!";
        }
      }
      return state;
    },
    reset: (state) => {
      state.webhooksDataFetched = false;
      state.webhooksDataUpdated = false;
      state.webhooksDataCreated = false;
      state.fetchWebhooksInfoError = "";
      return state;
    },
  },
});
export const {
  webhooksDataFetched,
  webhooksDataUpdated,
  webhooksDataCreate,
  reset,
} = webhooks.actions;
export default webhooks.reducer;
