import { call, put, takeEvery } from "redux-saga/effects";
import {
  configurationDataFetched,
  configurationDataUpdated,
  setClientConfiguration,
} from "../slice/configurations";

import { ConfigActions } from "../types";
import { sendApiRequest } from "../../lib/axios";

function* getClientConfigurations(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "configurations/fetch/",
    "get",
  );
  yield put(configurationDataFetched(result));
}

function* updateClientConfigurations(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "configurations/update/",
    "post",
  );
  yield put(configurationDataUpdated(result));
}

function* createClientConfigurations(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "configurations/create/",
    "post",
  );
  yield put(configurationDataFetched(result));
}

function* setConfiguration(action) {
  try {
    yield put({ type: setClientConfiguration, payload: action.payload });
  } catch (e) {
    console.log(`Error: ${e.message}`);
    yield put({ type: "setClientConfiguration", message: e.message });
  }
}

export function* watchConfigAsync() {
  yield takeEvery(
    ConfigActions.GET_CLIENT_CONFIG_ACTION,
    getClientConfigurations,
  );
  yield takeEvery(
    ConfigActions.UPDATE_CLIENT_CONFIG_ACTION,
    updateClientConfigurations,
  );
  yield takeEvery(
    ConfigActions.CREATE_CLIENT_CONFIG_ACTION,
    createClientConfigurations,
  );
  yield takeEvery(ConfigActions.SET_CONFIGURATION, setConfiguration);
}
