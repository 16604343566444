import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const drawerTheme = responsiveFontSizes(
  createTheme({
    spacing: 0,
    palette: {
      primary: {
        main: "#444e73", //"#0070ba", //indigo
      },
      secondary: {
        main: "#000000", //pink
      },
    },
  }),
);

export default drawerTheme;
