import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const HeadingCTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const CTypography = (props) => {
  const { children, variant, ...rest } = props;
  const variantTypes = ["h1", "h2", "h3", "h4", "h5", "h6"];
  if (variant && variantTypes.includes(variant))
    return (
      <HeadingCTypography variant={variant} {...rest}>
        {children}
      </HeadingCTypography>
    );
  else return <Typography {...rest}>{children}</Typography>;
};

export default CTypography;
