import { createSlice } from "@reduxjs/toolkit";
import { KeyMessages } from "constants/Messages";

const initialState = {
  applications: [],
  sharedSecret: "",
  fetchKeySuccess: false,
  fetchKeyError: false,
  fetchKeyErrorMessage: "",
  generateKeySuccess: false,
  generateKeyError: false,
  generateKeyErrorMessage: "",
  deleteKeySuccess: false,
  deleteKeyError: false,
  deleteKeyErrorMessage: "",
};

const keys = createSlice({
  name: "keys",
  initialState,
  reducers: {
    fetch: (state, action) => {
      if (!action.payload.error) {
        state.fetchKeySuccess = true;
        let response = action.payload.result;
        state.applications = response.applications;
        state.sharedSecret = response.shared_secret;
      } else {
        state.fetchKeyError = true;
        state.fetchKeyErrorMessage = KeyMessages.ERROR_FETCH_KEY_MESSAGE;
      }

      return state;
    },
    generate: (state, action) => {
      if (!action.payload.error) {
        state.generateKeySuccess = true;
        let response = action.payload.result;
        state.applications = [...state.applications, response];
      } else {
        state.generateKeyError = true;
        state.generateKeyErrorMessage = KeyMessages.ERROR_GENERATE_KEY_MESSAGE;
      }

      return state;
    },
    deleteIt: (state, action) => {
      if (!action.payload.result.error) {
        state.deleteKeySuccess = true;
        let clientId = action.payload.client_id;
        state.applications = state.applications.filter(
          (item) => item.client_id !== clientId,
        );
      } else {
        state.deleteKeyError = true;
        state.deleteKeyErrorMessage = KeyMessages.ERROR_DELETE_KEY_MESSAGE;
      }

      return state;
    },
    reset: (state) => {
      state.fetchKeySuccess = false;
      state.generateKeySuccess = false;
      state.fetchKeyError = false;
      state.fetchKeyErrorMessage = "";
      state.generateKeyError = false;
      state.generateKeyErrorMessage = "";
      state.deleteKeySuccess = false;
      state.deleteKeyError = false;
      state.deleteKeyErrorMessage = "";
      return state;
    },
  },
});
export const { fetch, generate, deleteIt, reset } = keys.actions;
export default keys.reducer;
