export const Disclosures = {
  TITLE_USA_PATRIOT_ACT: "USA PATRIOT Act Disclosure",
  MESSAGE_USA_PATRIOT_ACT:
    "To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you is that when you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver's license or other identifying documents.",
  MESSAGE_DISAGREE_USA_PATRIOT_ACT:
    "Please review and accept all disclosures shown to proceed.",
  TITLE_US_SERVICE_MEMBER: "U.S. Service Member",
  MESSAGE_USA_SERVICE_MEMBER:
    "Please inform us if you are a service member, veteran or family of a service member. It is our policy to comply with the letter and intent of the Servicemembers Civil Relief Act and the Military Lending Act and such other current and future state or federal laws which are enacted to  protect the interests of Servicemembers or Veterans. Grow Credit will use its best efforts to  ensure that Servicemembers and Veterans have full and complete access to its financial products  in a fair manner and without discrimination based on their military status.",
};

export const Reapplication = {
  TITLE: "Welcome Back",
  MESSAGE: "By pressing continue you can reapply for your Grow Credit loan.",
};

export const General = {
  ERROR_TITLE: "Error!",
};

export const ConfigurationObject = {
  clientName: "Blue Ridge Bank",
  isMockedMode: true,
};

export const BrandingObject = {
  icons: {
    clientLogo:
      "https://logos-download.com/wp-content/uploads/2016/04/U_S_Bank_logo_logotype_emblem-700x189.jpg",
    clientLogoDark:
      "https://go.mybrb.com/images/fi-assets/blue-ridge-bank/blue-ridge-bank-logo-e990c94f.png",
  },
  colors: {
    lightSelected: {
      primary: "#333333",
      secondary: "#FF0000",
      background: "#333333",
    },
    darkSelected: {
      primary: "#FF0000",
      secondary: "#9a1300",
      background: "#333333",
    },
    light: {
      primary: "#333333",
      primaryVariant: "#333333",
      onPrimary: "#333333",
      secondary: "#FF0000",
      secondaryVariant: "#333333",
      surface: "#333333",
      onSurface: "#333333",
      onSecondary: "#333333",
      background: "#333333",
      onBackground: "#9a1300",
      error: "#9a1300",
      onError: "#9a1300",
      walkThrough: "#eee9fa",
    },
    dark: {
      primary: "#FF0000",
      primaryVariant: "#333333",
      onPrimary: "#333333",
      secondary: "#FF0000",
      secondaryVariant: "#FF0000",
      surface: "#FF0000",
      onSurface: "#FF0000",
      onSecondary: "#FF0000",
      background: "#FF0000",
      onBackground: "#9a1300",
      error: "#9a1300",
      onError: "#9a1300",
      walkThrough: "#eee9fa",
    },
  },
};

export const ChangePasswordScreen = {
  REQUIRED_OLD_PASSWORD_ERROR: "Please provide an old password",
  REQUIRED_PASSWORD_ERROR: "Please provide a new password",
  VALID_PASSWORD_ERROR: "Please provide a valid new password",
  REQUIRED_CONFIEM_PASSWORD_ERROR: "Please confirm new password",
  MATCH_PASSWORD: "Password should match the new Password",
  BUTTON: "Change Password",
  OLD_PASSWORD_LABEL: "Old password",
  PASSWORD_LABEL: "New password",
  CONFIRM_PASSWORD_LABEL: "Confirm password",
  NEW_PASSWORD_NOT_SAME_ERROR:
    "New password must be different from the old password",
};
export const ContactUsScreen = {
  REQUIRED_FEEDBACK_ERROR: "please provide the feedback",
  BUTTON: "Contact Us",
  LABEL: "Feedback",
};

export const UserManagementScreen = {
  CREATE_USER: "Create User",
  UPDATE_USER: "Update User",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  USER_NAME: "Username",
  REQUIRED_FIRST_NAME_ERROR: "Please provide a first name",
  REQUIRED_LAST_NAME_ERROR: "Please provide a last name",
  VALID_NAME_ERROR: "Please provide only letters",
  VALID_PASSWORD_ERROR: "Please provide a valid new password",
  VALID_DOMAIN_ERROR: "Please enter a valid domain",
  REQUIRED_EMAIL_ERROR: "Please provide an email address",
  REQUIRED_USERNAME_ERROR: "Please provide a username",
  MATCH_PASSWORD: "Password should match the new Password",
  BUTTON: "Change Password",
  PASSWORD_LABEL: "Password",
  BUTTON_UPDATE: "Update User",
  BUTTON_CREATE: "Create User",
  ACTIVE_TEXT: "Active",
  CONFIRM_TEXT_TITLE: "Confirmation",
  CONFIRM_DIALOG_DISCRIPTION: "Are you sure, you want to update the user?",
};

export const AuthenticationAPIDocsConstants = {
  REQUIRED_CLIENT_ID_ERROR: "Please provide Client ID",
  REQUIRED_CLIENT_SECRET_ERROR: "Please provide a Client Secret",
  CLIENT_ID_LABEL: "Client Id",
  CLIENT_SECRET_LABEL: "Client Secret",
  GET_ACCESS_TOKEN: "Get Access Token",
  GET_TOKEN: "Get Token",
  GENERATE_TOKEN: "Generate Token",
  ACCESS_TOKEN_LABEL: "Access Token",
  API_GUIDE: "API Guide",
  CLIENT_KEYS_LABEL: "Client Keys",
};

export const EncodeCCFormConstants = {
  REQUIRED_USER_EMAIL_ERROR: "Please provide User Email address",
  REQUIRED_USER_PASSWORD_ERROR: "Please provide User password",
  VALID_EMAIL_ERROR: "Please provide a valid email address",
  VALID_PASSWORD_ERROR:
    "Atleast 1 lowercase, 1 uppercase, 1 numeric character and minimum 8 characters are required",
  USER_EMAIL_LABEL: "User Email",
  USER_PASSWORD_LABEL: "User Password",
  ENCODE_BUTTON: "Encode CC",
  ENCODED_CC_LABEL: "Encoded CC",
  ENCODED_CC_GENERATED: "Encoded CC Generated!",
};
