class TokenService {
  getLocalRefreshToken() {
    let refresh_token;

    if (window.localStorage.getItem("refresh_token")) {
      if (window.localStorage.getItem("refresh_token").charAt(0) === '"') {
        // Parse json in case if the user token is from web app
        refresh_token = JSON.parse(localStorage.getItem("refresh_token"));
      } else {
        //Return the token in case if the user token is from mobile app
        refresh_token = localStorage.getItem("refresh_token");
      }
    }
    return refresh_token;
  }

  getLocalAccessToken() {
    let token;
    if (window.localStorage.getItem("user_token")) {
      if (window.localStorage.getItem("user_token").charAt(0) === '"') {
        // Parse json in case if the user token is from web app
        token = JSON.parse(localStorage.getItem("user_token"));
      } else {
        //Return the token in case if the user token is from mobile app
        token = localStorage.getItem("user_token");
      }
    }
    return token;
  }

  updateLocalAccessToken(token) {
    window.localStorage.setItem("user_token", token);
  }

  updateLocalRefreshToken(refreshToken) {
    window.localStorage.setItem("refresh_token", refreshToken);
  }
}

export default new TokenService();
