import { AccountLoginMessages } from "constants/Messages";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  loginSuccess: false,
  logoutSuccess: false,
  loginSuccessMsg: "",
  loginError: false,
  loginErrorMessage: "",
  UserToken: "",
  RefreshToken: "",
  userRole: null,
  changePassValidationError: {},
  changePasswordSuccess: false,
  changePasswordSuccessMsg: "",
  changePasswordError: false,
  changePasswordErrorMsg: "",
  apiAccessToken: "",
  apiAccessTokenError: false,
  apiAccessTokenSuccess: false,
};

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    login: (state, action) => {
      if (action.payload.status === 200) {
        const { access, refresh, role } = action.payload.result;
        state.loginSuccess = true;
        state.loginError = false;
        state.loginSuccessMsg = AccountLoginMessages.SUCCESS_LOGIN_MESSAGE;
        state.UserToken = access;
        state.RefreshToken = refresh;
        state.userRole = role;
        window.localStorage.setItem("role", role);
        window.localStorage.setItem("user_token", access);
        window.localStorage.setItem("refresh_token", refresh);
      } else {
        state.loginSuccess = false;
        state.loginError = true;
        state.loginErrorMessage = AccountLoginMessages.ERROR_LOGIN_MESSAGE;
      }
      return state;
    },
    logout: (state, action) => {
      if (action.payload.status === 200 || action.payload.status === 500) {
        state.loginSuccess = false;
        state.logoutSuccess = true;
        state.isLoggedIn = false;
      }
      window.localStorage.clear();
      return state;
    },
    changePassword: (state, action) => {
      let response = action.payload;
      if (response.error) {
        if (response.result.message) {
          state.changePasswordError = true;
          state.changePasswordErrorMsg = response.result.message;
        } else {
          state.changePassValidationError = response.result;
        }
      } else {
        state.changePasswordSuccess = true;
        state.changePasswordSuccessMsg = response.result.message;
      }
      return state;
    },
    setApiDocsAccessToken: (state, action) => {
      let response = action.payload;
      if (response) {
        if (response.error) {
          state.apiAccessTokenError = true;
        } else {
          state.apiAccessTokenSuccess = true;
          state.apiAccessToken = response.result.access_token;
        }
      }

      return state;
    },
    resetApiDocsAccessToken: (state) => {
      state.apiAccessTokenError = false;
      state.apiAccessTokenSuccess = false;
      return state;
    },
    setUserAsLoggedOut: (state) => {
      state.isLoggedIn = false;
      //return state;
    },
    resetChangePassword: (state) => {
      state.changePassValidationError = {};
      state.changePasswordSuccess = false;
      state.changePasswordSuccessMsg = "";
      state.changePasswordError = false;
      state.changePasswordErrorMsg = "";
      return state;
    },
    resetAuth: (state) => {
      state.isLoggedIn = false;
      state.loginSuccess = false;
      state.logoutSuccess = false;
      state.loginError = false;
      state.loginSuccessMsg = "";
      state.loginErrorMessage = "";
      return state;
    },
  },
});
export const {
  login,
  logout,
  setUserAsLoggedOut,
  resetAuth,
  changePassword,
  resetChangePassword,
  setApiDocsAccessToken,
  resetApiDocsAccessToken,
} = authentication.actions;
export default authentication.reducer;
