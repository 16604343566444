import { call, put, takeEvery } from "redux-saga/effects";
import {
  login,
  logout,
  changePassword,
  setApiDocsAccessToken,
} from "../slice/authentication";

import { AuthActions } from "../types";
import { sendApiRequest } from "../../lib/axios";

function* logoutUser(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "auth/revoke/",
    "post",
  );
  yield put(logout(result));
}

function* loginUser(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "auth/access/",
    "post",
  );
  yield put(login(result));
}
function* changePasswordSaga(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "auth/password/update/",
    "post",
  );
  yield put(changePassword(result));
}

function* getApiDocsAccessToken(action) {
  const result = yield call(
    sendApiRequest,
    action.payload,
    "auth/access/token/",
    "post",
    "multipart",
  );
  yield put(setApiDocsAccessToken(result));
}

export function* watchAuthAsync() {
  yield takeEvery(AuthActions.LOGIN_ACTION, loginUser);
  yield takeEvery(AuthActions.LOGOUT_ACTION, logoutUser);
  yield takeEvery(AuthActions.CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(
    AuthActions.GENERATE_DOCS_ACCESS_TOKEN,
    getApiDocsAccessToken,
  );
}
