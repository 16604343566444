import { all } from "redux-saga/effects";
import { watchAuthAsync } from "./authentication";
import { watchBrandingAsync } from "./branding";
import { watchConfigAsync } from "./configurations";
import { watchDashboardAsync } from "./dashboard";
import { watchKeysAsync } from "./keys";
import { watchLogsAsync } from "./apilogs";
import { watchReportsAsync } from "./reports";
import { watchWebHookAsync } from "./webhooks";
import { watchWhitelistingAsync } from "./whitelisting";
import { watchUsersAsync } from "./users";

export function* rootSaga() {
  yield all([
    watchAuthAsync(),
    watchBrandingAsync(),
    watchLogsAsync(),
    watchConfigAsync(),
    watchDashboardAsync(),
    watchKeysAsync(),
    watchReportsAsync(),
    watchWebHookAsync(),
    watchWhitelistingAsync(),
    watchUsersAsync(),
  ]);
}
