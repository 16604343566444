export const ROLES = Object.freeze({
  PARENT: 1,
  CHILD: 2,
});

export const PERMISSIONS = {
  USER_MANAGEMENT: [ROLES.PARENT],
};

export const MODULES_LIST = {
  USER_MANAGEMENT: "USER_MANAGEMENT",
};
