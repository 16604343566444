import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const CButton = (props) => {
  const { children, onClick, ...rest } = props;

  return (
    <Button
      {...rest}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </Button>
  );
};

CButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
};
export default CButton;
